.register-status {
  background-color: $bg-theme-color;
  margin: 40px auto 0 auto;
  padding: 44px 100px;
  width: 100%;
  max-width: $sub-content-size;

  @include mq {
    @include mq('small', max, true) {
      width: 100% !important;
      padding: $spacing-small 0 !important;
    }
  }

  @include mq('large', max) {
    width: 100%;
    padding: $spacing-small 0;
  }
}

.register-status-table {
  font-size: $fz-default-text;
  font-weight: bold;
  margin: 0 auto;
  text-align: left;
  width: 100%;

  tr + tr {
    margin-top: 22px;
  }

  tr {
    td {
      padding-bottom: 5px;

      &:first-child {
        vertical-align: top;
        width: 30%;
      }
      &:nth-child(2) {
        padding-left: 50px;
        word-break: break-all;
        width: 70%;
      }
      #map {
        width: 100%;
        height: 300px;
      }
    }
  }

  @include mq('large', max) {
    width: 100% !important;
    padding: $spacing-small $spacing-small !important;
    tr,
    td,
    th {
      display: block;
      width: 100% !important;
    }
    td:nth-child(2) {
      padding-left: 0 !important;
    }
  }
}

.registration-text-container {
  font-size: $fz-default-text;
  font-weight: bold;
  margin: 16px auto 0 auto;
  text-align: left;
  width: 80%;

  div + div {
    margin-top: 36px;
  }

  &.fail {
    width: 50%;
  }
}
