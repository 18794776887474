.small-card {
  background-color: #ffffff;
  transition: all 250ms cubic-bezier(0.02, 0.01, 0.47, 1);
  width: 100%;
  margin: 0 auto;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.08);

  @include mq('large', max) {
    width: 100%;
  }

  &:hover {
    .small-card-thumbnail {
      &::before {
        background-color: rgba(0, 0, 0, 0.4);
        bottom: 0;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: background-color 300ms ease-in-out;
        width: 100%;
      }
    }
  }

  a {
    display: block;
    height: 100%;
    text-decoration: none;
  }

  &-thumbnail {
    height: 162px;
    position: relative;
    width: inherit;

    &::before {
      background-color: rgba(0, 0, 0, 0);
      bottom: 0;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: background-color 300ms ease-in-out;
      width: 100%;
    }

    > img {
      height: inherit;
      object-fit: cover;
      width: 100%;
    }
  }

  &-detail {
    padding: 24px 30px;

    h3 {
      font-size: $fz-22;
      line-height: calc(22px * 1.75);
    }

    > p {
      margin-top: 15px;
      text-align: center;

      time {
        font-size: $fz-small;
      }
    }
  }
}
