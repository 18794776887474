@charset 'UTF-8';

//submitボタンデフォルトスタイルのリセット
input[type="submit"] {
  -webkit-appearance: none;
  border-radius: 0;
  border-style:none;
}

.form {
  &-container {
    margin: 0 auto;
    padding: 0 13.6%;

    @include mq('large', max) {
      width: calc(100% - 16px);
      padding: 0;
    }
  }

  &-content {
    margin: 0 auto;
    width: 100%;
  }

  &-description {
    font-size: $fz-sub-text;
    text-align: left;
    width: 100%;
    &-date {
      display: none;
    }
    li {
      text-indent: -1em;
      margin-left: 1em;
    }
    .small_text01 {
      display: inline-block;
      line-height: 1;
    }
    .small_text02 {
      display: inline-block;
      font-size: 1.3rem;
      line-height: 1;
      vertical-align: top;
    }
    .form-warning {
      color: $fg-error;
      font-size: 14px;
    }
  }

  &-error-message {
    color: $fg-error;
    font-size: $fz-small;

    @include mq('small', max) {
      font-size: $fz-small !important;
    }
    &-payment {
      text-align: left;
      margin-top: $spacing-xsmall;
    }
  }

  &-button-wrap {
    margin-top: $spacing-middle;
    text-align: center;

    @include mq('medium', max) {
      display: flex;
      flex-direction: column-reverse;
    }

    .button {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  &-radio {
    &-group {
      text-align: left;

      > label:first-child {
        font-weight: bold;
      }

      &-items {
        margin-top: 14px;
      }
    }

    input {
      margin-right: 0.5em;
    }
  }

  &-item {
    position: relative;
    text-align: left;
    margin-top: 50px;
    padding-top: 50px;
    border-top: 1px solid #ccc;

    &:first-of-type {
      border-top: none;
      margin-top: 0;
    }

    &.required {
      .form-label {
        position: relative;
        &::before {
          content: '*';
          color: $fg-error;
          display: block;
          font-size: $fz-24;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: unset;
          left: -20px;
          line-height: 1;
          position: absolute;
          top: 0;
          @include mq('large', max) {
            left: -16px;
          }
        }
      }
    }
  }

  &-input {
    position: relative;
    width: 100%;
    display: flex;

    &.name {
      width: calc(50% - 8px);

      @include mq('small', max) {
        width: 100%;
      }
    }

    &-name-container {
      display: flex;

      input + input {
        margin-left: 8px;
      }
    }

    label {
      color: #333333;
      display: block;
      position: absolute;
      text-align: left;
      top: -30px;
    }

    input {
      background: none;
      border: none;
      border-bottom: 1px solid #444444;
      border-radius: 0;
      display: block;
      font-size: $fz-sub-text;
      height: 40px;
      line-height: 30px;
      outline: none;
      width: 100%;
    }

    .expiration-date-wrap {
      display: flex;

      input {
        display: block;
        width: calc(20% - 8px);
      }

      span {
        display: block;
        line-height: 40px;
      }

      * + * {
        margin-left: 8px;
      }
    }
  }

  &-select {
    position: relative;

    &::after {
      background-image: url('../../../images/wpg/icon/arrow-down.svg');
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      display: block;
      height: 9px;
      position: absolute;
      right: 0;
      bottom: 10px;
      width: 13px;
      pointer-events: none;
    }

    select {
      -webkit-appearance: none;
      background-color: transparent;
      border: none;
      border-bottom: 1px solid #444444;
      border-radius: 0;
      font-size: $fz-sub-text;
      height: 32px;
      text-align: left;
      width: 100%;
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }

    &.date {
      width: 100%;
      text-align: left;

      &::after {
        display: none;
      }
    }

    &-date {
      width: 8em;
      max-width: 32%;
      display: inline-block;
      margin-top: 0 !important;

      span{
        position: relative;
        margin-right: 8%;
        display: inline-block;
        width: 6em;
        max-width: 60%;

        &::after {
          background-image: url('../../../images/wpg/icon/arrow-down.svg');
          background-repeat: no-repeat;
          background-size: contain;
          content: '';
          display: block;
          height: 9px;
          position: absolute;
          right: -5px;
          top: 16px;
          width: 13px;
          pointer-events: none;
        }
      }
    }

    @include mq('small', max) {
      width: 100%;
    }
  }

  &-textarea {
    position: relative;
    width: 100%;
    margin-top: $spacing-xsmall;

    label {
      color: #333333;
      display: block;
      margin-bottom: 8px;
      text-align: left;
    }

    textarea {
      -webkit-appearance: none;
      background-color: #e6e3e0;
      border: #444444 1px solid;
      border-radius: 0px;
      display: block;
      letter-spacing: 1px;
      outline: 0;
      overflow: auto;
      resize: vertical;
      width: 100%;
    }
  }

  &-checkbox {
    input {
      margin-right: 8px;
    }
  }

  // キャンペーン
  &-campaign {
    &-radio {
      > p {
        text-align: left;
        font-size: $fz-small;

        & + div {
          margin-top: 8px;
        }
      }

      &-input {
        max-width: $sub-content-size;
      }
    }

    &-detail {
      margin-top: 16px;
      max-width: $sub-content-size;
      text-align: left;

      > p {
        font-size: $fz-sub-text;
        margin-top: $spacing-middle;
      }

      > div {
        margin-top: 8px;
        font-size: $fz-sub-text;
      }
    }

    &-caption-detail {
      p {
        margin: 0 !important;
      }
    }
  }

  // 有料ロッカー
  &-locker {
    &-caption {
      text-align: left;
      margin-top: 20px;
    }

    &-note {
      text-indent: -1em;
      margin-left: 1em;
    }
  }

  // 名前
  &-name {
    .first_name {
      margin-right: 5px;
    }

    .last_name {
      margin-left: 5px;
    }
  }

  // 郵便番号
  &-zipcode {
    .form-input {
      align-items: baseline;
      input {
        width: 50%;
        margin-right: 20px;
      }
      .button {
        margin-top: 0;
        padding: 5px 40px;
      }
    }
    @include mq('small', max) {
      .form-input {
        flex-flow: wrap;
        input {
          width: 100%;
          margin-right: 0;
        }
        .button {
          width: 100%;
          margin-top: 20px;
        }
      }
    }
  }

  // 決済情報
  &-credit-container,
  &-transfer-container {
    display: none;
    text-align: left;

    &.show {
      display: block;
    }

    .form-error-message {
      display: none;
    }

    .tooltip {
      display: inline;
      position: relative;
      &_icon {
        background-color: #876430;
        color: #fff;
        padding: 4px;
        border-radius: 15px;
      }
      &_text {
        display: none;
        span {
          text-align: left;
          line-height: 1.3;
          background-color: #876430;
          opacity: .9;
          color: #fff;
          max-width: 80vw;
          padding: 4px 10px;
          border-radius: 5px;
          display: inline-block;
        }
      }
    }
  }
}

//カレンダー
.flatpickr {
  &-months {
    .flatpickr-prev-month.flatpickr-prev-month {
      top: 8px;
    }
    .flatpickr-next-month.flatpickr-next-month {
      top: 8px;
    }
  }
  &-month {
    height: 40px !important;
  }
  &-monthDropdown-months {
    display: inline-block;
    height: 40px !important;
  }
}

// 店舗絞り込み
.form-account {
  &-label {
    color: #333333;
    position: absolute;
    text-align: left;
  }
  &-container {
    text-align: left;
  }
}
.account-select-area {
  display: flex;
  label {
    color: #333333;
    display: block;
    text-align: left;
    font-size: $fz-small;
    line-height: 1;
    margin-top: 5px;
  }
  .area-select {
    border-bottom: 1px solid #444444;
    display: inline-block;
    width: 120px;
  }
  .prefecture-select {
    border-bottom: 1px solid #444444;
    margin: 0 20px;
    width: 100px;
  }
  .account-select {
    border-bottom: 1px solid #444444;
  }
  @include mq('medium', max) {
    display: block;
    label {
      top: -20px;
    }
    .prefecture-select {
      margin: initial;
      margin-top: 20px;
    }
    .account-select {
      margin-top: 20px;
    }
  }
}

// 店舗名選択 select2
.select2 {
  &-container {
    .select2-dropdown {
      background-color: #eeeeee;
      margin-top: 2px;
      box-sizing: content-box;
    }
    .select2-search--dropdown {
      padding: 8px;
      .select2-search__field {
        background-color: #f0eff0;
        border-radius: 3px;
        font-size: 14px;
        &:focus {
          outline: none;
        }
      }
    }
    .select2-results__option {
      padding: 8px;
    }
  }

  &-selection {
    height: 40px;
    appearance: none;
    -webkit-appearance: none;
    border: none;
    background-color: transparent;
    border-radius: 0;
    display: block;
    padding: 0 5px;
    &:focus {
      outline: none;
    }
  }
  &-hidden-accessible {
    display: none;
  }
  &-selection__placeholder {
    color: #757575;
    font-size: $fz-sub-text;
  }
  &-container--default {
    .select2-selection--single {
      background-color: #e6e3e0;
      border: none;
      height: 28px;
      margin-top: 2px;
      width: 100%;
    }
  }
  &-selection__arrow {
    display: none;
  }
  &-selection__rendered {
    padding: 0!important;
    font-size: $fz-sub-text;
    @include mq('medium', max) {
      line-height: 18px;
    }
  }
}

// 入力フローナビゲーション
.form-nav {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  &_list {
    display: flex;
    align-items: center;
    &:after {
      content: ">";
      color: #876430;
      margin: 0 .5em;
    }
    &:last-child:after {
      display: none;
    }
  }

  &_text {
    border: 2px solid #c69a59;
    padding: 2px 5px;
    &_active {
      background-color: #c69a59;
    }
  }

  &_num {
    background-color: #c69a59;
    color: #fff;
    padding: 5px;
    &_active {
      background-color: #fff;
      color: #c69a59;
    }
  }
  &_title {
    &_active {
      color: #fff;
    }
  }
  @include mq('medium', max) {
    &_text {
      border: none;
      &_active {
        background-color: initial;
      }
    }
    &_num {
      padding: 10px;
      border-radius: 5px;
    }
    &_title {
      display: none;
    }
  }
}

.formbox .dl_shop {
  display: flex;
}

.form2_pref select {
  margin-left: 0;
}

.form2_shops {
  padding-top: 30px;

  &.parsley-error .form2_shops__item {
    background-color: #ffe8e8;
    border-color: #e00000;
  }
}

.form2_shops__item {
  border: 2px solid black;
  border-top: none;
  padding: 20px;
  position: relative;
}

.form2_shops__item:first-child {
  border-top: 2px solid black;
}

.form2_shops__item-info {
  display: block;
}

.form2_shops__item-info__addr,
.form2_shops__item-info__tel {
  font-size: 16px;
  font-weight: normal;
  padding-top: 5px;
}

.form2_shops__item-info__addr {
  padding-top: 15px;
}

.form2_shops__link {
  position: absolute;
  display: block;
  background-color: black;
  color: white;
  right: 20px;
  bottom: 20px;
  text-decoration: none;
  padding: 4px 15px 5px 15px;
  line-height: 1;
  font-size: 14px;
  font-weight: bold;
  transition: .3s ease;
  z-index: 2;
}

.form2_shops__link:hover {
  background-color: #555;
}

@media only screen and (max-width: 999px) {
  .formbox .dl_shop {
      display: block;
      margin: 0 10px;
      padding: 10px 0;
  }
}