@charset 'UTF-8';
// font-family
$Serif: '游明朝', YuMincho, 'ヒラギノ明朝 ProN W3', 'Hiragino Mincho ProN',
'HG明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
$Sans-serif: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'メイリオ',
Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;

// font-size
$fz-max: 5rem; // 80px
$fz-input: 3rem;
$fz-46: 2.875rem;
$fz-36: 2.25rem;
$fz-32: 2rem;
$fz-26: 1.625rem;
$fz-24: 1.5rem;
$fz-22: 1.375rem;
$fz-20: 1.25rem;
$fz-18: 1.125rem;
$fz-default-text: 16px;
$fz-sub-text: 0.8rem;
$fz-small: 0.75rem;

// SP
$sp-fz-heading: 4rem;
$fz-sp-default-text: 14px;
