.informations-container {
  margin-top: $spacing-middle;

  section {
    margin-top: 0;
  }

  & + .pager {
    margin-top: $spacing-middle;
  }

  .small-card {
    box-shadow: none;
  }

  @include mq('small', max) {
    margin-top: 0;
  }
}

