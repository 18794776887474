.price-table {
  background-color: #f0eff0;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 40px auto 0 auto;
  table-layout: fixed;
  width: calc(100% * 3 / 4);

  th,
  td {
    font-weight: normal;
    padding: 8px 1rem;
  }

  tr > * {
    border-right: 1px solid #cccccc;
    :last-child {
      border-right: none;
    }
  }

  .bold {
    font-weight: bold;
  }
  .default_price {
    display: none;
  }

  thead {
    background-color: $fg-theme-color;
    font-size: $fz-sub-text;

    th {
      color: #ffffff;
    }
  }

  tbody {
    font-size: $fz-small;
    .th-note {
      display: block;
      font-size: 0.5rem;
    }
    .free_period {
      display: none;
    }
    .price-locker {
      display: none;
    }
    .border-top {
      border-top: 1px solid #cccccc;
    }
    .border-bottom {
      border-bottom: 1px solid #cccccc;
    }
  }

  @include mq('small', max) {
    width: 100%;
    margin: $spacing-small auto 0 auto;
    th {
      padding: 4px 8px;
    }
    .price-heading {
      th {
        font-size: $fz-small;
      }
    }
  }
}
