@charset 'UTF-8';
// TODO 1024 table系
/*
 * NOTE
 * 1. screen and (min-width: XXpx)とする場合
 *    @include mq(xsmall) {} -> @media screen and (min-width: 480px)
 * 2. screen and (max-width: XXpx)とする場合
 *    @include mq(xsmall, max) -> @media screen and (max-width: 480px)
 *    第二引数にmaxを指定するとmax-width: 指定した値として吐き出される
 * 3. screen and (min-width: XXpx) and (max-width: YYpx)とする場合
 *    @include mq {
 *      @include mq(xsmall, max, true){
 *      }
 *    }
 *    第一引数に指定したものよりmq-breakpointsの次の値のものを-1pxした値が入る
 *    この場合最終outputは @media screen and (min-width: 480px) and (max-width: 1023px)となる
 */

$mq-breakpoints: (
  'small': 480px,
  'msmall' : 600px,
  'medium': 768px,
  'medium-1': 767px,
  'large' : 1024px,
  'large+1' : 1025px,
  'xlarge': 1280px,
);

@mixin mq($breakpoint : medium, $rule: min, $subtraction: false) {
  $breakpoint: map_get($mq-breakpoints, $breakpoint);
  @if $rule == max and $subtraction {
    $breakpoint: $breakpoint - 1;
  }
  @media screen and (#{$rule}-width: $breakpoint) {
    @content;
  }
}
