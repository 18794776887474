.faq-container {
  margin-top: $spacing-middle;

  .tab-item {
    margin: 0 auto;
    width: 80%;

    * {
      text-align: left;
    }

    p:first-child {
      margin-top: 32px;
    }

    * + * {
      margin-top: 16px;
    }

    ul {
      display: block;
    }

    li {
      list-style-type: disc;
      margin: 0;
      width: 100%;

      p:first-child {
        font-weight: bold;
      }

      ul,
      ol {
        margin-left: 1rem;
      }
    }

    ol {
      li {
        list-style-type: decimal;
      }
    }
  }

  @include mq('large', max) {
    .tab-item {
      p:first-child {
        margin-top: 0;
      }

      ul {
        margin-top: 0;

        li:not(:first-child) {
          margin-top: $spacing-small;
        }
      }
    }
  }
}
