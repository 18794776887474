// NOTE ruby側で作られたクラス名にスタイルを当てるため
.pagination {
  margin-top: $spacing-middle;

  display: flex;
  justify-content: center;

  // NOTE 自動生成のコードのスタイルを消す
  .page {
    margin-top: 0;
  }

  span {
    & + span {
      margin-left: 10px;
    }
  }

  span,
  a {
    font-size: $fz-24;
    font-weight: bold;
    padding: 18px 10px;
  }

  a {
    background-color: #f0eff0;
    text-decoration: none;

    &:hover {
      opacity: 0.8;
    }
  }
  .gap, .next, span:nth-of-type(4) {
    margin-left: 0px;
  }

  @include mq('medium-1', max) {
    width: 100%;
    flex-wrap: wrap;

    .gap {
      display: none;
    }
    span {
      & + span {
        margin-left: 0px;
      }
    }
    span, a {
      font-size: $fz-default-text;
      padding: 10px 3px;
    }
  }
}