#company {
  .imag_area {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 70px;

    @include mq('large', max) {
      margin-top: 50px;
    }
  }

  img {
    max-width: 700px;
    width: 80%;

    @include mq('large', max) {
      width: 100%;
    }
  }
}
