@charset 'UTF-8';
@import 'styles/util/index';
@import 'styles/parts/index';
@import 'styles/page/index';

@font-face {
  font-family: 'kozuka-pr6n-r';
  src: url('./fonts/kozuka-pr6n-r.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'kozuka-pr6n-r';
  overflow-x: hidden;
}

body::before {
  -webkit-transform: translate(-112%, 0) matrix3d(1, 0, 0, 0, -0.65, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transform: translate(-112%, 0) matrix3d(1, 0, 0, 0, -0.65, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  visibility: hidden;
}

body::after {
  -webkit-transform: translate(-20%, 0) matrix3d(1, 0, 0, 0, 0.65, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transform: translate(-20%, 0) matrix3d(1, 0, 0, 0, 0.65, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  visibility: visible;
}

body.is-loaded::after {
  transform: translate(90%, 0) matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
}

body.is-trans::before {
  transform: translate(0, 0) matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  visibility: visible;
}
a {
  color: initial;
  &:hover {
    color: initial; }
}

@import 'styles/main';
