@charset 'UTF-8';

.button {
  &:hover {
    cursor: pointer;
    opacity: 0.8;
    @include mq('large', max) {
      opacity: 1;
    }
  }

  &.primary {
    background-color: $fg-theme-color;
    color: #ffffff;
  }

  &.large {
    font-size: $fz-20;
  }

  &.solid {
    border: outset;
  }

  &:disabled {
    opacity: 0.5;
  }

  &.white {
    background-color: white;
    border: $fg-theme-color 2px solid;
    color: $fg-theme-color;
    font-weight: bold;
  }

  &-list {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    width: 500px;

    @include mq {
      @include mq('small', max, true) {
        width: 100%;
        flex-direction: column-reverse;
      }
    }
  }

  @include mq('small', max) {
    padding: 16px 40px;
  }
}

// トップのお問い合わせボタン
.top-inquiry-button {
  display: block;
  margin: 40px auto 40px auto;
  text-align: center;
  font-size: 16px;
}
