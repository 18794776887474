.tab-navigation {
  width: 100%;

  ul {
    display: flex;
    justify-content: center;

    li {
      padding-bottom: 8px;
      font-size: $fz-24;
      cursor: pointer;
      
      & + li {
        margin-left: 24px;
      }

      &.current {
        border-bottom: 2px solid $fg-theme-color;
        font-weight: bold;
      }
    }
  }

  @include mq('small', max) {
    ul {
      li {
        font-size: $fz-sp-default-text;
      }
    }
  }

  &.faq {
    @include mq {
      @include mq('small', max, true) {
        ul {
          display: block;
        }
        li {
          margin-left: 0;
          display: inline-block;
          width: 100%;

          & + li {
            margin-top: 8px;
          }
        }
      }
    }
    @include mq('small', max) {
      ul {
        display: block;
      }
      li {
        margin-left: 0;
        display: inline-block;
        width: 100%;

        & + li {
          margin-top: 8px;
        }
      }
    }
  }
}

.tab-content {
  > section {
    display: none;

    &.show {
      animation-duration: 0.5s;
      animation-name: fade-in;
      display: block;
      opacity: 1;
    }
  }
}

@keyframes fade-in {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

.tab-item {
  p:first-child {
    font-size: $fz-24;

    & + p {
      margin-top: 16px;
    }

    @include mq('small', max) {
      font-size: $fz-sp-default-text;
    }
  }

  > small {
    font-size: $fz-default-text;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 40px;

    li {
      width: calc(100% / 3 - 32px);

      p {
        text-align: center;
      }

      & + li {
        margin-left: 16px;
      }
    }
  }
}
