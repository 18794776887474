.button-list {
  margin-top: 80px;
  width: initial;
}
.note {
  margin-top: 20px;
}
.discription-title {
  font-weight: bold;
  margin: 20px 0px;
}
.discription {
  margin-left: 20px;
}
.transfer {
  margin-top: 80px;
}
.payment-name {
  display:none;
}