.heading-container {
  > h2 {
    font-family: $Serif;
    font-size: $fz-max;
    font-weight: 200;
    letter-spacing: 5.29px;
    line-height: 1em;
    text-transform: uppercase;
  }

  > p {
    display: inline-block;
    font-size: $fz-20;
    margin-top: 4px;

    &::before {
      content: '_';
      padding: 0 8px;
    }
  }

  @include mq {
    @include mq('small', max, true) {
      > h2 {
        font-size: $fz-26;
      }
      > p {
        font-size: $fz-sp-default-text;
      }
    }
  }
  @include mq('small', max) {
    > h2 {
      font-size: $fz-26;
    }
    > p {
      font-size: $fz-sp-default-text;
    }
  }
}
//IE11対応
.is-msie {
  .heading-container {
    padding-top: 50px;
  }
  #price_system {
    padding-top: 50px;
  }
}
