#admission_new {
  .form-campaign-content {
    text-align: left;
  }

  .radio_error {
    display: block;
    margin-top: initial;
    margin-bottom: 10px;
    position: initial;
    top: initial;
  }
  .form-account-container {
    border-bottom: none;
    width: 100%;

    .account-select-error {
      top: 45px;
    }
  }
  .form-select {
    width: 100%;
  }
  .form-select select {
    border-bottom: none;
  }
  .area-select,
  .prefecture-select {
    width: 200px;
  }
  .form-select-date span {
    border-bottom: 1px solid #444444;
  }
  .caution {
    color: $fg-error;
  }
  .caution-description {
    background-color: #f6f4f6;
    border-radius: 5px;
    padding: 15px;
  }
  .contact-link {
    background-color: #f6f4f6;
    border-radius: 5px;
    display: inline-block;
    padding: 10px 15px;
  }
  .link-text {
    color: $fg-theme-color;
    font-weight: bold;
  }
  @include mq('large', max) {
    .asterisk::after {
      left: -16px;
    }
  }
}
