#price_system {
  padding-top: 100px;

  .system-table {
    border-collapse: collapse;
    table-layout: fixed;
    margin: auto;
    width: 90%;
    max-width: 1000px;
    margin-top: 50px;
  }
  th {
    background-color: #876430;
    color: #ffffff;
    font-weight: normal;
    text-align: left;
    width: 242px;
    font-size: $fz-default-text;
    font-weight: bold;
    padding: 15px;
    vertical-align: initial;
  }
  sub {
    color: white;
    display: inline-block;
    font-weight: initial;
    line-height: 1.5;
  }
  .th-name {
    color: white;
    display: block;
    margin-bottom: 20px;
  }

  td {
    background-color: #f0eff0;
    height: 80px;
    padding: 15px 10px 15px 15px;
  }

  td:last-child {
    text-align: -webkit-right;
    padding-right: 25px;
    width: 100px;
  }
  td, p {
    color: #444444;
    font-size: $fz-default-text;
  }
  .td01 {
    border-top: 2px dotted #ebe7df;
  }
  .td02 {
    border-top: 2px dotted #ebe7df;
  }
  .description-list{
    color: #444444;
    font-size: $fz-small;
  }
  .border-none {
    border: none;
  }

  @include mq('large', max) {
    padding-top: initial;

    th {
      width: initial;
    }
  }
  @include mq(small, max) {
    th, tr, td {
      display:block;
      width: 100%;
    }
    tr {
      border-bottom:30px #876430 solid;
    }
    td {
      height: initial;
      padding: 15px;
    }
    td:last-child {
      text-align: initial;
      padding-right: initial;
      width: initial;
    }
    .td01, .td02 {
      border-top: none;
    }
    .last-tr {
      border-bottom: none;
    }
  }
}