// ワールドジムの特徴 //
.about-container {
  display: flex;

  & + .about-container {
    margin-top: $spacing-large;

    @include mq('small', max) {
      margin-top: $spacing-small;
      flex-direction: column;
    }
  }

  > div {
    position: relative;
    width: 50%;

    & + div {
      margin-left: $spacing-middle;

      @include mq('small', max) {
        margin-left: 0;
      }
    }

    h3 {
      font-weight: normal;

      & + p {
        margin-top: 40px;
      }
    }

    p + p {
      margin-top: 8px;
    }

    img {
      object-fit: cover;
      width: 100%;

      &.broken-grid {
        bottom: -45px;
        height: calc(100vh * 0.4);
        position: absolute;
        right: 0;

        @include mq('small', max) {
          position: initial;
        }
      }
    }

    @include mq('small', max) {
      width: 100%;
    }
  }

  @include mq('small', max) {
    flex-direction: column-reverse;
  }
}
@include mq('large', max) {
  #about_us {
    padding-top: initial;
  }
}

//IE11対応
.is-msie {
  h3 {
    font-size: 1.5rem;
  }
  .th-name {
    letter-spacing: 1px;
    line-height: 2;
  }
  .description-list {
    letter-spacing: 1px;
    line-height: 2;
  }
}
