.container {
  padding: 0 40px;
  @include mq('large', max) {
    padding: $spacing-middle $spacing-middle;
  }
  @include mq('small', max) {
    padding: $spacing-small $spacing-small;
  }
}

.container.even {
  background-color: $bg-secondary-color;
  padding: 80px 40px;
  width: 100%;
  @include mq('large', max) {
    padding: $spacing-middle $spacing-middle;
  }
  @include mq('small', max) {
    padding: $spacing-small $spacing-small;
  }
}

//IEの場合grid-row子要素を3つ横並びにする
@mixin grid-row($col, $col-msie, $width, $gap-column: 0, $gap-row: 0) {
  display: grid;
  grid-template-columns: repeat($col, $width);
  gap: 36px 36px;
  .is-msie & { // IE判定クラス
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    & > * {
      width: calc((100% - 1px - #{$gap-column * ($col-msie - 1)}) / #{$col-msie});
      //margin-left: $gap-column;
      &:nth-child(#{$col-msie}n #{"-"} #{$col-msie - 1}) {
        //margin-left: 0;
      }
      &:nth-child(#{$col-msie}) ~ * {
        margin-top: $gap-row;
      }
    }
  }
}
.grid-row {
  //display: grid;
  //grid-gap: 36px;
  //grid-template-columns: repeat(auto-fit, minmax(372px, 1fr));
  width: 100%;
  margin: $spacing-middle auto 0 auto;
  @include grid-row(auto-fit, 3, minmax(372px, 1fr), 36px, 36px);

  & + div {
    margin-top: 36px;
  }

  &.shop-sns-instagrams {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 8px;
  }

  &.equipment {
    grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
    @include mq('large', max) {
      grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    }
    @include mq('small', max) {
      grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    }
  }

  &.small {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    @include mq('small', max) {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }
  }

  &.large {
    grid-template-columns: repeat(auto-fit, minmax(580px, 1fr));

    @include mq('large', max) {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
    @include mq('small', max) {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }
  }

  &.informations {
    grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
    @include mq('large', max) {
      grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    }
    @include mq('md') {
      grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    }
    @include mq('small', max) {
      grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    }
  }

  &.top-news {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    @include mq('xlarge', max) {
      grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
    }
    @include mq('medium-1', max) {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }
  }

  //店舗一覧のカード
  &.search,
  &.shop {
    margin-top: 0;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));

    @include mq('large', max) {
      section {
        margin-top: 0;
      }
    }
  }

  &.shop {
    margin-left: 0;
  }

  @include mq('small', max) {
    grid-gap: 20px;
  }
}
.sp-hide {
  @include mq('large', max) {
    display: none !important;
  }
}
.sp-hide2 {
  @include mq('medium-1', max) {
    display: none !important;
  }
}
.sp-hide3 {
  @include mq('medium', max, true) {
    display: none !important;
  }
}
.pc-hide {
  @include mq('large') {
    display: none !important;
  }
}
.pc-hide2 {
  @include mq('medium') {
    display: none !important;
  }
}
.pc-hide3 {
  @include mq('large+1') {
    display: none !important;
  }
}

//IE11対応
.is-msie {
  p {
    letter-spacing: 1px;
    line-height: 2;
  }

  //top,newsカード
  // #top {
  //   .grid-row.small {
  //     display: -ms-grid;
  //     display: grid;
  //     grid-gap: 36px;
  //     width: 100%;
  //     margin: $spacing-middle auto 0 auto;
  //   }
  //   .grid-row.small > *:nth-child(1) {
  //     -ms-grid-row: 1;
  //     -ms-grid-column: 1;
  //     width: 95%;
  //   }
  //   .grid-row.small > *:nth-child(2) {
  //     -ms-grid-row: 1;
  //     -ms-grid-column: 2;
  //     width: 95%;
  //   }
  //   .grid-row.small > *:nth-child(3) {
  //     -ms-grid-row: 1;
  //     -ms-grid-column: 3;
  //     width: 95%;
  //   }
  // }
}
