@charset "utf-8";

/* reset
----------------------------------------------- */

h1,
h2,
h3,
h4,
h5,
p,
ul,
ol,
li,
dl,
dt,
dd,
main,
figure,
figcaption {
  display: block;
  margin: 0;
  padding: 0;
  font-weight: normal;
  border: 0;
  outline: 0;
  line-height: 1;
  list-style: none;
}
a {
  margin: 0;
  padding: 0;
  outline: none;
}
img {
  vertical-align: top;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-drag: none;
  -ms-user-drag: none;
  user-drag: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
::selection {
  color: #fff;
  background: #bba15c;
}

/* html
----------------------------------------------- */

html {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-print-color-adjust: exact;
}

/* body
----------------------------------------------- */

body {
  margin: 0;
  padding: 0;
  color: #000;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 400;
  background-color: #f0eff0;
}

/* disable */

.jsbody--disable {
  pointer-events: none;
}

/* a
----------------------------------------------- */

a {
  color: #000;
}

/* link
----------------------------------------------- */

.link {
}
.link:after {
  display: inline-block;
  content: "";
  margin: 0 0 5px 5px;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: 20px;
}

/* blank */

.link[target="_blank"]:after {
  background-image: url("../images/common/icon_blank.svg");
}

/* pdf */

.link[href$=".pdf"]:after {
  background-image: url("../images/common/icon_pdf.svg");
}

/* click to call */

.link--tel {
  color: inherit;
  text-decoration: none;
}

/* loading
----------------------------------------------- */

.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}
.loading__mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 1;
}
.loading__progress {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
}
.loading__icon {
  width: 40px;
  height: 40px;
  background-image: url("../images/common/loading.svg");
  background-repeat: no-repeat;
  background-size: 40px;
  z-index: 1;
  animation-name: loading;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  margin: 0 0 0 -20px;
}
.loading__message {
  font-size: 14px;
  line-height: 2;
  text-align: center;
  font-weight: bold;
  position: relative;
  left: -50%;
  top: 10px;
}

/* start */

.js-loading-start .loading__progress {
  transform: scale(0);
  transition-property: transform;
  transition-duration: 0.25s;
}
.js-loading-start .loading__mask {
  transform: scaleX(0);
  transition-property: transform;
  transition-duration: 0.5s;
  transform-origin: left;
  transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
}

/* wrapper
----------------------------------------------- */

.wrapper {
  position: relative;
  word-break: break-word;
  overflow: hidden;
  z-index: 1;
}

/* button
----------------------------------------------- */

.button {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 48px;
  text-decoration: none;
  line-height: normal;
  outline: none;
  border: 0;
  background: transparent;
  cursor: pointer;
  z-index: 1;
  -webkit-appearance: none;
}
.button:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid #000;
  border-radius: 48px;
  background-color: #000;
  box-sizing: border-box;
  z-index: 1;
}
.button__label {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 2px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.05em;
  z-index: 2;
}

/* gold */

.button--gold:after {
  border-color: #bba15c;
  background-color: #bba15c;
}

/* goldline */

.button--goldline:after {
  border-color: #bba15c;
  background-color: #fff;
}
.button--goldline .button__label {
  color: #bba15c;
}

/* blackline */

.button--blackline:after {
  background-color: transparent;
}
.button--blackline .button__label {
  color: #000;
}

/* redline */

.button--redline {
  height: 40px;
}
.button--redline:after {
  border-color: #890000;
  background-color: transparent;
}
.button--redline .button__label {
  color: #890000;
}

/* blueline */

.button--blueline {
  height: 40px;
}
.button--blueline:after {
  border-color: #002f87;
  background-color: transparent;
}
.button--blueline .button__label {
  color: #002f87;
}

/* white */

.button--white:after {
  border-color: #fff;
  background-color: #fff;
}
.button--white.button--anchor:before {
  background-position: -144px -24px;
}
.button--white .button__label {
  color: #000;
}

/* whiteline */

.button--whiteline:after {
  border-color: #fff;
}

/* reset */

.button__reset {
  display: block;
  position: absolute;
  top: 50%;
  right: 115px;
  margin: -12px 0 0;
  padding: 0;
  width: 24px;
  height: 24px;
  font-size: 0;
  outline: none;
  border: 0;
  background-image: url("../images/common/button_icon.svg");
  background-position: -168px 0;
  background-repeat: no-repeat;
  background-size: 264px 48px;
  background-color: transparent;
  cursor: pointer;
  z-index: 2;
  -webkit-appearance: none;
}

/* icon */

.button--location:before,
.button--arrow:before,
.button--anchor:before,
.button--down:before,
.button--left:before,
.button--right:before,
.button--search:before,
.button--close:before,
.button--back:before,
.button--redline:before,
.button--blueline:before {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  right: 15px;
  margin: -12px 0 0;
  width: 24px;
  height: 24px;
  background-image: url("../images/common/button_icon.svg");
  background-repeat: no-repeat;
  background-size: 264px 48px;
  z-index: 2;
}
.button--location:before {
  background-position: 0 0;
}
.button--arrow:before {
  background-position: -120px 0;
}
.button--anchor:before {
  background-position: -144px 0;
}
.button--down:before {
  background-position: -24px 0;
}
.button--left:before {
  background-position: -72px 0;
}
.button--right:before {
  background-position: -48px 0;
}
.button--search:before {
  background-position: -96px 0;
}
.button--close:before {
  background-position: -168px 0;
}
.button--back:before {
  left: 15px;
  right: auto;
  background-position: -192px -24px;
}
.button--redline:before {
  right: 10px;
  background-position: -216px -24px;
}
.button--blueline:before {
  right: 10px;
  background-position: -240px -24px;
}
.button--location .button__label,
.button--arrow .button__label,
.button--anchor .button__label,
.button--down .button__label,
.button--left .button__label,
.button--right .button__label,
.button--search .button__label,
.button--close .button__label,
.button--redline .button__label,
.button--blueline .button__label {
  padding: 0 15px 0 0;
}
.button--back .button__label {
  padding: 0 0 0 15px;
}

/* disabled */

.button:disabled {
  pointer-events: none;
  opacity: 0.15;
}

/* more
----------------------------------------------- */

.more {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 30px;
  outline: none;
  border: 0;
  background: transparent;
  cursor: pointer;
  z-index: 1;
  -webkit-appearance: none;
}
.more:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid #000;
  border-radius: 30px;
  background-color: #000;
  box-sizing: border-box;
  z-index: 1;
}
.more__label {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 1px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  z-index: 2;
}

/* icon */

.more--icon .more__label:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  margin: -6px 0 0;
  width: 18px;
  height: 12px;
  background-image: url("../images/common/more_arrow.svg");
  background-repeat: no-repeat;
  background-size: 18px 24px;
  z-index: 1;
}

/* white */

.more--white:after {
  border-color: #fff;
  background-color: #fff;
}
.more--white .more__label {
  color: #000;
}
.more--white .more__label:after {
  background-position: 0 -12px;
}

/* whiteline */

.more--whiteline:after {
  border-color: #fff;
  background-color: #000;
}
.more--whiteline .more__label {
  color: #fff;
}

/* header
----------------------------------------------- */

.header {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  z-index: 10;
}
@media only screen and (max-width: 999px) {
  .wrapper::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 64px;
    background: #000;
  }
}
.header__inner a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 226px;
  height: 241px;
  text-decoration: none;
}
.header__copy {
  color: #cba457;
  font-size: 11px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.03em;
  line-height: 16px;
}
.header__logo {
  margin: 5px 0 0;
  text-align: center;
}

/* footer
----------------------------------------------- */

.footer {
  background-color: #000;
  overflow: hidden;
}
.footer__outer {
  margin: 0 30px;
}
.footer__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  max-width: 1280px;
  height: 100px;
}
.footer__lists {
  font-size: 0;
}
.footer__list {
  display: inline-block;
  position: relative;
  margin: 0 20px 0 0;
  padding: 0 20px 0 0;
  z-index: 1;
}
.footer__list:last-child {
  margin: 0;
  padding: 0;
}
.footer__list:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  margin: -5px 0 0;
  width: 1px;
  height: 10px;
  background-color: #fff;
  z-index: 1;
}
.footer__list:last-child:after {
  display: none;
}
.footer__list a {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  letter-spacing: 0.02em;
}
.footer__copyright {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

/* nav
----------------------------------------------- */

.nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 128px;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  z-index: 5;
}
.nav__outer {
  margin: 0;
}
.nav__inner {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.nav__covid {
  margin: 15px 0 0;
  width: 250px;
}
.nav__login {
  margin: 0 20px 0 0;
  width: 250px;
}
.nav__lists {
  margin: 0;
  font-size: 0;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.nav__lists._main {
  margin: 10px 44px 0 0;
}
.nav__menu_buttons {
  margin: 0 0 0 24px;
  display: flex;
}
.nav__menu_buttons li {
  width: 64px;
  height: 64px;
}
.nav__menu_buttons li a {
  font-size: 12px;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 700;
  line-height: normal;
  width: 64px;
  height: 64px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  box-sizing: border-box;
}
.nav__menu_buttons li a._login {
  color: #fff;
  background: #000;
}
.nav__menu_buttons li a._search {
  color: #fff;
  background: #C5A563;
}
.nav__menu_buttons li a._join {
  color: #fff;
  background: #C80000;
}
.nav__list {
  display: inline-block;
  margin: 0 0 0 35px;
}
.nav__list a {
  display: block;
  padding: 10px 0;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  line-height: 1.2;
}
.nav__infos {
  display: none;
}
.nav__covid .button__label {
  font-size: 15px;
}

/* side */

.nav__side {
  display: flex;
  align-items: center;
}
.nav__side .nav__covid {
  margin: 0;
}
.nav__side .nav__list {
  margin-left: 24px;
}
.nav__side .nav__list a {
  font-size: 14px;
}

/* sitemap
----------------------------------------------- */

.sitemap {
  position: relative;
  background-color: #fff;
  z-index: 1;
}
.sitemap:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  transform: scaleX(0);
  transform-origin: right;
  z-index: 1;
}
.sitemap__outer {
  margin: 0 30px;
}
.sitemap__inner {
  margin: auto;
  max-width: 1200px;
}
.sitemap__columns {
  display: flex;
  padding: 80px 0;
}
.sitemap__column:first-child {
  width: 280px;
}
.sitemap__column:last-child {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 280px);
}
.sitemap__logo img {
  width: 236px;
  height: auto;
}
.sitemap__copy {
  margin: 15px 0 0;
  color: #cba457;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.03em;
}
.sitemap__lists {
}
.sitemap__list {
  margin: 15px 0 0 30px;
}
.sitemap__list a {
  display: inline-block;
  position: relative;
  padding: 0 0 0 20px;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  letter-spacing: 0.03em;
  line-height: 20px;
  z-index: 1;
}
.sitemap__list a:before {
  display: block;
  content: "";
  position: absolute;
  top: 1px;
  left: 0;
  margin: 6px 2px 0 0;
  width: 9px;
  height: 7px;
  vertical-align: top;
  background-image: url("../images/common/sitemap_arrow.svg");
  background-repeat: no-repeat;
  background-size: 9px 7px;
  z-index: 1;
}

/* ready */

.js-indicate-ready.sitemap:after {
  transform: scaleX(1);
}

/* start */

.js-indicate-start.sitemap:after {
  transform: scaleX(0);
  transition-property: transform;
  transition-duration: 0.85s;
  transition-timing-function: cubic-bezier(0.87, 0, 0.13, 1);
}

/* main
----------------------------------------------- */

.main {
  position: relative;
  margin: 128px 0 0;
  z-index: 1;
}

/* quickseach
----------------------------------------------- */

.quickseach {
  position: relative;
  background-color: #000;
  box-shadow: 5px 30px 20px rgba(0, 0, 0, 0.28);
  z-index: 1;
}
.quickseach__inner {
  margin: 0;
}
.quickseach__label {
  position: absolute;
  top: -20px;
  left: 0;
  padding: 0 40px;
  height: 40px;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.03em;
  line-height: 39px;
  background-color: #bba15c;
  z-index: 2;
}
.quickseach__columns {
  display: flex;
  flex-wrap: wrap;
  margin: 0 30px;
  padding: 60px 0;
}
.quickseach__location {
  margin: 0 10px;
  width: 210px;
}
.quickseach__box {
  margin: 0 10px;
  width: calc(100% - 250px);
}
.quickseach__area {
  margin: 25px 10px 0;
  width: calc(100% - 20px);
}
.quickseach__field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 48px;
  word-break: normal;
  border-radius: 48px;
  background-color: #3c3c3c;
  z-index: 1;
}
.quickseach__field:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 20px;
  margin: -12px 0 0;
  width: 24px;
  height: 24px;
  background-image: url("../images/common/button_icon.svg");
  background-position: -96px 0;
  background-repeat: no-repeat;
  background-size: 264px 48px;
  z-index: 1;
}
.quickseach__field input {
  margin: 0 10px 0 60px;
  padding: 0;
  width: calc(100% - 166px);
  height: 50px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  outline: none;
  border: 0;
  background: transparent;
}
.quickseach__field .button {
  margin: 0 6px 0 0;
  width: 90px;
  height: 38px;
}
.quickseach__field .button__label {
  font-size: 14px;
}

/* single */

.quickseach--single .quickseach__columns {
  display: block;
  padding: 40px 0;
}
.quickseach--single .quickseach__location,
.quickseach--single .quickseach__box,
.quickseach--single .quickseach__area {
  margin: 20px 0;
  width: 100%;
}

/* spot */

.quickseach--spot {
  margin: 40px 0 0;
  background-color: transparent;
  box-shadow: none;
}
.quickseach--spot .quickseach__box {
  margin: 0;
}

/* maparea */

.quickseach--maparea {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 310px;
  background-color: transparent;
  box-shadow: none;
  z-index: 2;
}
.quickseach--maparea .quickseach__field input {
  width: calc(100% - 70px);
}

/* title
----------------------------------------------- */

.title {
}
.title__text {
  margin: -15px 0 0;
  color: #414141;
  font-size: 18px;
  font-weight: 700;
}

/* white */

.title--white .title__text {
  color: #efefed;
}

/* ready */

.js-indicate-ready.title .title__label {
  opacity: 0;
  transform: translateX(-50px);
}
.js-indicate-ready.title .title__text {
  opacity: 0;
  transform: translateY(25px);
}

/* start */

.js-indicate-start.title .title__label,
.js-indicate-start.title .title__text {
  opacity: 1;
  transform: translate(0px);
  transition-property: opacity, transform;
  transition-duration: 0.65s;
  transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
}

/* franchise
----------------------------------------------- */

.franchise {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  z-index: 1;
}
.franchise__title {
  font-size: 60px;
  font-weight: 900;
  text-align: center;
  letter-spacing: 0.1em;
}
.franchise__title small {
  display: block;
  margin: 0 0 10px;
  font-size: 40px;
  letter-spacing: 0;
}
.franchise__copy {
  margin: 40px 0 0;
  font-size: 28px;
  font-weight: 700;
}

/* corporatemember
----------------------------------------------- */

.corporatemember {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  z-index: 1;
}
.corporatemember__copy {
  font-size: 28px;
  font-weight: 700;
  text-align: center;
}
.corporatemember__title {
  margin: 15px 0 0;
  font-size: 60px;
  font-weight: 900;
  text-align: center;
  text-indent: 0.07em;
  letter-spacing: 0.07em;
  line-height: 70px;
}

/* newsindex
----------------------------------------------- */

.newsindex {
}
.newsindex__lists {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 -30px;
  padding: 15px 0 0;
}
.newsindex__list {
  margin: 30px 0 0 30px;
  width: calc(50% - 30px);
}
.newsindex__list a {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  color: #fff;
  text-decoration: none;
  background-color: #414141;
}
.newsindex__image {
  margin: 0 0 0 10px;
  width: 28.23%;
}
.newsindex__image img {
  width: 100%;
  height: auto;
}
.newsindex__main {
  margin: 0 10px 0 20px;
  width: calc(71.77% - 20px);
}
.newsindex__header {
  display: flex;
}
.newsindex__date {
  margin: 6px 0 0;
  width: 90px;
  font-size: 16px;
}
.newsindex__categories {
  margin: 0 0 0 15px;
  width: calc(100% - 100px);
  font-size: 0;
}
.newsindex__category {
  display: inline-block;
  margin: 5px 0 0 5px;
  width: 111px;
  height: 21px;
  font-size: 11px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.1em;
  line-height: 19px;
  background-color: #cba457;
}
.newsindex__subject {
  margin: 10px 0 0;
  font-size: 16px;
  line-height: 30px;
}
.newsindex__more {
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin: 40px 0 0;
  z-index: 1;
}
.newsindex__more:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 3px;
  background-color: #fff;
  z-index: -1;
}

/* more */

.newsindex__more .more {
  width: 180px;
}

/* guidance
----------------------------------------------- */

.guidance {
  background-color: #dbdada;
}
.guidance__outer {
  margin: 0 30px;
}
.guidance__inner {
  margin: auto;
  padding: 80px 0;
  max-width: 1200px;
}
.guidance__columns {
  display: flex;
  justify-content: space-between;
}
.guidance__column {
  display: flex;
  flex-direction: column;
  width: calc(50% - 15px);
}
.guidance__title {
  font-size: 26px;
  font-weight: 700;
}
.guidance__text {
  margin: 15px 0 0;
  padding: 0 0 50px;
  font-size: 16px;
  line-height: 30px;
}
.guidance__more {
  margin: auto 0 0;
}

.guidance__more {
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin: 30px 0 0;
  z-index: 1;
}
.guidance__more:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 3px;
  background-color: #000;
  z-index: -1;
}

/* single */

.guidance--single .guidance__columns {
  display: block;
}
.guidance--single .guidance__column {
  width: auto;
}

/* more */

.guidance__more .more {
  width: 150px;
}

/* cover
----------------------------------------------- */

.cover {
  position: relative;
  z-index: 1;
}
.cover:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 200px;
  background-position: center top;
  background-size: cover;
  z-index: 1;
}
.cover__outer {
  position: relative;
  margin: 0 30px;
  z-index: 2;
}
.cover__inner {
  margin: auto;
  padding: 151px 0 0;
  max-width: 1200px;
}
.cover__title {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  font-size: 60px;
  font-weight: 900;
  text-align: center;
  text-indent: 0.07em;
  letter-spacing: 0.07em;
  line-height: 70px;
  z-index: 2;
}
.cover__image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
}

/* wide */

.cover--wide:after {
  height: 450px;
}
.cover--wide .cover__inner {
  padding: 405px 0 0;
}

/* bg */

.cover--bg {
  margin: 0;
}
.cover--bg:after {
  height: 150px;
}
.cover--bg .cover__inner {
  padding: 150px 0 0;
}

/* image */

.cover--shop:after {
  background-image: url("../images/common/cover_image_shop_pc@2x.jpg");
}
.cover--contact:after {
  background-image: url("../images/common/cover_image_contact_pc@2x.png");
}
.cover--news:after {
  background-image: url("../images/common/cover_image_news_pc@2x.jpg");
}
.cover--about:after {
  background-image: url("../images/common/cover_image_about_pc@2x.jpg");
}
.cover--admission:after {
  background-image: url("../images/common/cover_image_admission_pc@2x.jpg");
}
.cover--franchise:after {
  background-image: url("../images/common/cover_image_franchise_pc@2x.jpg");
}
.cover--company:after {
  background-image: url("../images/common/cover_image_company_pc@2x.jpg");
}
.cover--corporatemember:after {
  background-image: url("../images/common/cover_image_corporatemember_pc@2x.jpg");
}

/* franchise */

.cover--franchise .cover__inner,
.cover--corporatemember .cover__inner,
.cover--recruit .cover__inner {
  padding: 0;
  height: 450px;
}

/* location
----------------------------------------------- */

.location {
  position: absolute;
  top: 10px;
  right: 40px;
  z-index: 2;
}
.location__text {
}
.location__text a {
  margin: 0 0 0 15px;
  color: #fff;
  font-size: 12px;
  text-decoration: none;
  line-height: 18px;
}
.location__text a:after {
  display: inline-block;
  content: "";
  margin: 0 0 4px 15px;
  width: 5px;
  height: 8px;
  vertical-align: bottom;
  background-image: url("../images/common/location_arrow.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 5px 16px;
}
.location__text a:last-child:after {
  display: none;
}

/* content
----------------------------------------------- */

.content {
}
.content__outer {
  margin: 0 30px;
}
.content__inner {
  margin: auto;
  padding: 0 0 80px;
  max-width: 1200px;
}
.content__title {
  margin: 40px 0 0;
  font-size: 44px;
  font-weight: 700;
}
.content__title i {
  color: #bba15c;
  font-style: normal;
}
.content__h2 {
  position: relative;
  padding: 80px 0 30px;
  font-size: 30px;
  font-weight: 700;
  line-height: 35px;
  z-index: 1;
}
.content__h2:after {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 3px;
  background-color: #000;
  z-index: 1;
}
.content__h2 em {
  color: #bba15c;
  font-style: normal;
}
.content__h3 {
  margin: 40px 0 0;
  font-size: 26px;
  font-weight: 700;
  line-height: 30px;
}
.content__h3 small {
  font-size: 16px;
}
.content__text {
  margin: 20px 0 0;
  font-size: 16px;
  line-height: 30px;
}
.content__text small {
  display: inline-block;
  color: #414141;
  font-size: 12px;
  line-height: 18px;
}
.content__title + .content__text {
  margin-top: 45px;
}
.content__text--white {
  color: #fff;
}
.content__text--last {
  margin: 80px 0 0;
}
.content__more {
  margin: 80px auto 0;
  width: 580px;
}
.content__list {
  margin: 20px 0 0 25px;
}
.content__list li {
  display: list-item;
  font-size: 16px;
  text-align: justify;
  line-height: 30px;
}
ul.content__list li {
  list-style: disc;
}
ol.content__list li {
  list-style: decimal;
}

/* textbox */

.content__textbox {
  padding: 25px 0 0;
}
.content__textbox p {
  margin: 10px 0 0;
  font-size: 16px;
  line-height: 30px;
}

/* line */

.content__h2--line {
  padding: 70px 0 0;
}

/* large */

.content__h2--large {
  font-size: 44px;
  line-height: 54px;
}
.content__h2--large small {
  display: block;
  font-size: 22px;
}

/* white */

.content__h2--white {
  color: #fff;
}
.content__h2--white:after {
  background-color: #fff;
}

/* black */

.content--black {
  background-color: #000;
}
.content--black .concept__text {
  color: #fff;
}

/* white */

.content--white {
  background-color: #fff;
}

/* column */

.content__columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 0 0 -30px;
  padding: 10px 0 0;
}
.content__column {
  margin: 30px 0 0 30px;
}
.content__columns--2 .content__column {
  width: calc(50% - 30px);
}
.content__columns--3 .content__column {
  width: calc(33.33% - 30px);
}
.content__columns--4 .content__column {
  width: calc(25% - 30px);
}
.content__columns--5 .content__column {
  width: calc(20% - 30px);
}

/* cautions */

.content__cautions {
  padding: 20px 0 0;
}
.content__caution {
  padding: 0 0 0 16px;
  font-size: 16px;
  text-align: justify;
  text-indent: -16px;
  line-height: 30px;
}

/* table */

.content__table {
  margin: 20px 0 0;
}
.content__table tr {
}
.content__table th,
.content__table td {
  font-size: 16px;
  font-weight: normal;
  text-align: justify;
  vertical-align: top;
  line-height: 30px;
}
.content__table th {
  white-space: nowrap;
}
.content__table td {
  padding-left: 25px;
}

/* dl */

.content__dl {
  margin: 20px 0 0;
}
.content__dl dt,
.content__dl dd {
  font-size: 16px;
  font-weight: normal;
  text-align: justify;
  vertical-align: top;
  line-height: 30px;
}
.content__dl dt {
  font-weight: bold;
}
table .content__dl {
  margin: 0;
}

/* ready */

.js-indicate-ready.content__h2:after {
  transform: scaleX(0);
  transform-origin: left;
}

/* start */

.js-indicate-start.content__h2:after {
  transform: scaleX(1);
  transition-property: transform;
  transition-duration: 0.5s;
  transition-delay: 0.15s;
  transition-timing-function: cubic-bezier(0.87, 0, 0.13, 1);
}

/* topics
----------------------------------------------- */

.topics {
  margin: 40px 0 0;
  background-color: #fff;
}
.topics__inner {
  display: flex;
  justify-content: space-between;
  margin: 0 30px;
  padding: 30px 0;
}
.topics__main {
  width: calc(100% - 380px);
}
.topics__title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}
.topics__date {
  display: block;
  margin: 15px 0 0;
  color: #414141;
  font-size: 18px;
}
.topics__text {
  margin: 20px 0 0;
  font-size: 16px;
  text-align: justify;
  line-height: 30px;
}
.topics__image {
  position: relative;
  width: 350px;
  background-color: #efefed;
  z-index: 1;
}
.topics__image img {
  width: 100%;
  height: auto;
}
.topics__image a {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.topics__image a:after {
  display: block;
  content: "";
  position: absolute;
  right: -15px;
  bottom: -15px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-image: url("../images/common/icon_zoom.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 16px;
  background-color: #000;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  z-index: 1;
}

/* plan
----------------------------------------------- */

.plan {
}
.plan__inner {
}
.plan__lists {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 -30px;
}
.plan__list {
  margin: 45px 0 0 30px;
  padding: 0 0 15px;
  width: calc(50% - 30px);
  background-color: #414141;
}
.plan__title {
  padding: 10px 0;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  line-height: 30px;
  background-color: #bba15c;
}
.plan__label {
  margin: 8px 0;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}
.plan__plus {
  margin: 4px 0;
  height: 16px;
  background-image: url("../images/common/icon_plus.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 16px;
}

.plan__table {
  margin: 4px 10px;
}
.plan__table table {
  width: 100%;
  background-color: #fff;
}
.plan__table tr {
  border-top: 2px solid #414141;
}
.plan__table tr:first-child {
  border: 0;
}
.plan__table th,
.plan__table td {
  padding: 10px 25px;
  font-size: 16px;
  text-align: left;
  line-height: 26px;
}
.plan__table th {
  width: 26%;
  background-color: #efefed;
  white-space: nowrap;
}
.plan__table td {
  padding: 10px 15px 10px 25px;
  width: 74%;
}
.plan__table td em {
  display: inline-block;
  color: #e00000;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
}
.plan__table td sup {
  font-size: 0.6em;
}

.plan__caution {
  margin: 5px 10px 0;
}
.plan__caution li {
  padding: 0 0 0 12px;
  color: #efefed;
  font-size: 12px;
  text-indent: -12px;
  line-height: 20px;
}
.plan__caution li em {
  color: #e00000;
  font-style: normal;
}

/* wide */

.plan--wide .plan__lists {
  display: block;
  margin: 0;
}
.plan--wide .plan__list {
  margin: 45px 0 0 0;
  padding: 0 0 16px;
  width: auto;
}
.plan--wide .plan__title {
  margin: 0 0 20px;
}
.plan--wide .plan__table {
  margin: 4px 20px;
}
.plan--wide .plan__table td em {
  font-size: 23px;
}
.plan--wide .plan__table td em small {
  font-size: 20px;
}

/* sns
----------------------------------------------- */

.sns {
  margin: 80px 0 0;
  border: 4px solid #000;
  box-sizing: border-box;
}
.sns__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}
.sns__label {
  font-size: 28px;
  font-weight: 700;
}
.sns__lists {
  margin: 0 0 0 10px;
  font-size: 0;
}
.sns__list {
  display: inline-block;
  margin: 0 0 0 20px;
}
.sns__list a {
  display: block;
  position: relative;
  width: 50px;
  height: 50px;
  text-indent: 100%;
  white-space: nowrap;
  border-radius: 50%;
  background-color: #000;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  z-index: 1;
}
.sns__list a:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -15px 0 0 -15px;
  width: 30px;
  height: 30px;
  background-image: url("../images/common/icon_sns.svg");
  background-repeat: no-repeat;
  background-size: 90px 30px;
  z-index: 1;
}

/* icon */

.sns__list--facebook a:after {
  background-position: 0 0;
}
.sns__list--twitter a:after {
  background-position: -30px 0;
}
.sns__list--instagram a:after {
  background-position: -60px 0;
}

/* localnav
----------------------------------------------- */

.localnav {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dbdada;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.27);
  z-index: 4;
}
.localnav__outer {
  margin: 0 30px;
}
.localnav__inner {
  margin: auto;
  max-width: 1200px;
}
.localnav__lists {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 -30px;
  height: 90px;
}
.localnav__list {
  margin: 0 0 0 30px;
  width: calc(33.33% - 30px);
}
.localnav__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.localnav__label {
  width: 40%;
  font-size: 18px;
  font-weight: 700;
}
.localnav__text {
  width: 60%;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
}
.localnav__text--tel a {
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
}
.localnav__text--tel a:before {
  display: inline-block;
  content: "";
  margin: 0 0 -3px;
  width: 24px;
  height: 24px;
  vertical-align: baseline;
  background-image: url("../images/common/icon_tel.svg");
  background-repeat: no-repeat;
  background-size: 24px 24px;
}
.localnav__more {
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin: 10px 0 0;
  z-index: 1;
}
.localnav__more:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: -1px 0 0;
  height: 3px;
  background-color: #000;
  z-index: -1;
}

/* more */

.localnav__more .more {
  width: 150px;
}

/* ready */

.js-localnav-ready {
  transform: translateY(100%);
}

/* start */

.js-localnav-start {
  transform: translateY(0);
  transition-property: transform;
  transition-duration: 0.85s;
  transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
}

/* modal
----------------------------------------------- */

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}
.modal__mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}
.modal__outer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 60px;
  left: 60px;
  right: 60px;
  bottom: 60px;
  z-index: 1;
}
.modal__inner {
  position: relative;
  margin: auto;
  max-width: 650px;
  background-color: #fff;
  z-index: 1;
}
.modal__image {
  padding: 30px;
  max-width: 650px;
}
.modal__image img {
  width: 100%;
  height: auto;
}
.modal__close {
  position: absolute;
  top: -20px;
  right: -20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #000;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  cursor: pointer;
  z-index: 1;
}
.modal__close:after {
  display: block;
  content: "";
  position: absolute;
  margin: -12px 0 0 -12px;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  background-image: url("../images/common/button_icon.svg");
  background-position: -168px 0;
  background-size: 264px 48px;
  z-index: 1;
}

/* initial */

.modal {
  pointer-events: none;
  visibility: hidden;
}
.modal__mask {
  opacity: 0;
}
.modal__inner {
  opacity: 0;
  transform: rotate(0.09deg) scale(0.98);
}

/* ready */

.js-modal-ready {
  transition-property: visibility;
  transition-duration: 0s;
  transition-delay: 0.25s;
}
.js-modal-ready .modal__mask {
  transition-property: opacity;
  transition-duration: 0.25s;
}
.js-modal-ready .modal__inner {
  transition-property: opacity, transform;
  transition-duration: 0.25s;
}

/* open */

.js-modal-open {
  pointer-events: auto;
  visibility: visible;
  transition-delay: 0s;
}
.js-modal-open .modal__mask {
  opacity: 1;
}
.js-modal-open .modal__inner {
  opacity: 1;
  transform: rotate(0deg) scale(1);
}

/* faqguide
----------------------------------------------- */

.faqguide {
  margin: 50px 0 0;
  background-color: #dbdada;
}
.faqguide__columns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 30px;
  padding: 20px 0;
}
.faqguide__column:first-child {
  width: 40.48%;
}
.faqguide__column:last-child {
  width: 50.61%;
}
.faqguide__text {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

/* step
----------------------------------------------- */

.step {
  margin: 45px 0 0;
  background-color: #dbdada;
}
.step__lists {
  display: flex;
  margin: 0 40px 0 20px;
  padding: 30px 0;
}
.step__list {
  position: relative;
  margin: 0 0 0 20px;
  width: 100%;
  z-index: 1;
}
.step__list:before,
.step__list:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
}
.step__list:before {
  left: 0;
  right: 30px;
  background-color: #414141;
}
.step__list:after {
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 0 20px 30px;
  border-color: transparent transparent transparent #414141;
}
.step__list span {
  display: block;
  position: relative;
  padding: 0 5px 0 0;
  height: 40px;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  line-height: 39px;
  z-index: 2;
}
.step__list i {
  font-style: normal;
}

/* current */

.step__list--current {
  animation-name: step;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}
.step__list--current:before {
  background-color: #000;
}
.step__list--current:after {
  border-color: transparent transparent transparent #000;
}
.step__list--current span {
  color: #cba457;
}

/* need
----------------------------------------------- */

.need {
  display: inline-block;
  position: relative;
  margin: 0 4px 4px;
  width: 50px;
  height: 22px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.1em;
  vertical-align: middle;
  line-height: 21px;
  background-color: #e00000;
  z-index: 1;
}

/* formbox
----------------------------------------------- */

.formbox {
  margin: 30px 0 0;
  background-color: #fff;
}
.formbox__item {
  border-top: 3px solid #f0eff0;
}
.formbox__item:first-child {
  border-top: 0;
}
.formbox dl {
  display: flex;
  margin: 0 30px;
  padding: 30px 0 25px;
}
.formbox dt {
  padding: 5px 0 0;
  width: 380px;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
}
.formbox dd {
  width: calc(100% - 280px);
  font-size: 18px;
}
.formbox dd > *:first-child {
  margin-left: 0;
}
.formbox dd label {
  font-weight: 700;
}

.formbox .aform-name-ul {
  display: flex;
  justify-content: space-between;
  width: 91%;
}
.formbox .aform-name-ul li {
  display: flex;
  align-items: center;
  width: calc(50% - 15px);
}
.formbox .aform-name-ul li label {
  display: inline-block;
  margin: 0 10px 0 0;
  font-size: 16px;
}

.formbox .aform-radio-ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 0 0 -60px !important;
  min-height: 40px;
}
.formbox .aform-radio-ul li {
  margin: 0 0 0 60px;
}

.aform-zip-ul {
  display: flex;
  margin: 0 0 0 -10px !important;
}
.aform-zip-ul li {
  margin: 0 0 0 10px !important;
}

/* table */

.formbox table {
  width: 91%;
}
.formbox th,
.formbox td {
  padding: 10px 20px;
  font-size: 16px;
  text-align: left;
  vertical-align: top;
  line-height: 30px;
  border-top: 5px solid #fff;
  background-color: #f0eff0;
}
.formbox th {
  width: 35%;
  border-right: 3px solid #fff;
  background-color: #e2e2e2;
}
.formbox td {
  width: 65%;
}
.formbox th label {
  display: inline-block;
}
.formbox th label:before {
  top: 5px !important;
  margin-top: 0px !important;
}
.formbox th label:after {
  top: 9px !important;
  margin-top: 0px !important;
}

/* price */

.formbox__price--first {
  margin: 80px 0 0;
}
.formbox__price table {
  width: 100%;
}
.formbox__price th,
.formbox__price td {
  border: 0;
  border-bottom: 2px solid #000;
  background-color: #fff;
}
.formbox__price th {
  width: 43%;
  background-color: #efefed;
}
.formbox__price td {
  width: 57%;
  text-align: right;
}
.formbox__price thead th {
  color: #cba457;
  font-size: 22px;
  text-align: center;
  background-color: #000;
}

/* note */

.formbox__note {
  margin: 5px 0 0;
  color: #414141;
  font-size: 12px;
  line-height: 18px;
}

/* field */

.formbox textarea,
.formbox input[type="text"],
.formbox input[type="password"],
.formbox input[type="tel"],
.formbox input[type="email"],
.formbox select {
  display: block;
  margin: 0;
  padding: 0 0 0 10px;
  width: calc(100% - 14px);
  width: 75%;
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  vertical-align: bottom;
  line-height: normal;
  outline: none;
  border: 2px solid #000;
  border-radius: 0;
  background: transparent;
  box-sizing: border-box;
  -webkit-appearance: none;
  transition-property: border-color, box-shadow;
  transition-duration: 0.2s;
}
.formbox textarea {
  padding: 10px;
  width: 100%;
  height: 80px;
  overflow: hidden;
}
.formbox select {
  display: inline-block;
  margin: 0 5px;
  padding: 0 64px 0 10px;
  width: auto;
  vertical-align: middle;
  background-image: url("../images/common/select_arrow.svg");
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 14px 14px;
}
.formbox .aform-name-ul li input {
  width: calc(100% - 14px);
}
/*
.formbox .error {
	border-color: #E00000;
	background-color: #FFE8E8;
}
*/
.formbox select::-ms-expand {
  display: none;
}
.formbox .ui-datepicker-trigger {
  margin: 0 0 4px 5px;
  vertical-align: middle;
}
.formbox input[type="radio"] {
  display: none;
}
.formbox input[type="radio"] + label {
  position: relative;
  padding: 0 0 0 40px;
  cursor: pointer;
  z-index: 1;
}
.formbox input[type="radio"] + label:before,
.formbox input[type="radio"] + label:after {
  display: inline-block;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  margin: -10px 0 0;
  width: 20px;
  height: 20px;
  border: 2px solid #000;
  border-radius: 50%;
  background-color: #fff;
  box-sizing: border-box;
}
.formbox input[type="radio"] + label:after {
  margin: -6px 0 0 4px;
  width: 12px;
  height: 12px;
  background-color: #000;
  transform: rotate(0.09deg) scale(0);
  transition-property: transform;
  transition-duration: 0.1s;
}
.formbox input[type="radio"]:checked + label:after {
  transform: rotate(0deg) scale(1);
}

/* type */

.formbox__item--address input[type="text"] {
  width: 92%;
}
.formbox__item--number input[type="text"] {
  width: 130px;
}
.formbox__item--zip input[type="text"] {
  width: 380px;
}
.formbox__item--zip .button {
  display: inline-block;
  width: 130px;
  height: 30px;
  margin: 5px 0 0 0;
}
.formbox__item--zip .button__label {
  font-size: 14px;
}
.formbox__item--id-verification-doc {
  .button {
    width: 270px;
    height: 30px;
    margin-top: 16px;
  }

  .parsley-error {
    &:after {
      border-color: #e00000;
      background-color: #ffe8e8;
    }

    &:hover:after {
      border-color: #e00000;
      background-color: #fcf5f5;
      transform: scale(1.025, 1.07);
    }

    .button__label {
      color: #e00000;
    }
  }

  img {
    border: 2px solid darkgray;
    border-radius: 16px;
    margin-top: 16px;
    width: 100%;
    max-width: 400px;
  }

  .button__label {
    font-size: 14px;
  }
}

/* agree */

.formbox--agree input {
  display: none;
}
.formbox--agree label {
  display: inline-block;
  position: relative;
  margin: 0 15px 2px 0;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  border: 2px solid #000;
  box-sizing: border-box;
  cursor: pointer;
  z-index: 1;
}
.formbox--agree label:after {
  display: block;
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 14px;
  height: 14px;
  background-image: url("../images/common/checkbox.svg");
  background-repeat: no-repeat;
  background-size: 14px;
  z-index: 1;
  transform: rotate(0.09deg) scale(0);
  transition-duration: 0.15s;
}
.formbox--agree input[type="checkbox"]:checked + label {
  background-color: #000;
}
.formbox--agree input[type="checkbox"]:checked + label:after {
  transform: rotate(0deg) scale(1);
}
.formbox--agree .formbox__item {
  display: flex;
  align-items: center;
  margin: 0 20px;
  padding: 40px 0;
}
.formbox__column:first-child {
  width: 80px;
}
.formbox__column:last-child {
  padding: 5px 0 0;
  width: calc(100% - 80px);
}
.formbox__text {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.05em;
}

/* focus */

.formbox textarea:focus,
.formbox input[type="text"]:focus,
.formbox input[type="tel"]:focus,
.formbox input[type="email"]:focus,
.formbox select:focus {
  border-color: #bba15c;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
}

/* aform */

.aform-required {
  display: inline-block;
  margin: 0 0 2px 10px;
  width: 50px;
  height: 22px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.1em;
  vertical-align: middle;
  line-height: 20px;
  background-color: #e00000;
}
.aform-error {
  margin: 5px 0 0;
  color: #e00000;
  font-size: 16px;
  line-height: 20px;
}
.aform-error label {
  font-weight: 400 !important;
}
.aform-input-example {
  margin: 0 0 10px;
  font-size: 16px;
  line-height: 22px;
}
.aform-confirm {
  display: inline-block;
  padding: 5px 0 10px;
  font-size: 16px;
  line-height: 26px;
}
.formbox textarea.error,
.formbox input[type="text"].error,
.formbox input[type="tel"].error,
.formbox input[type="email"].error,
.formbox select.error,
.formbox textarea.parsley-error,
.formbox input[type="text"].parsley-error,
.formbox input[type="password"].parsley-error,
.formbox input[type="tel"].parsley-error,
.formbox input[type="email"].parsley-error,
.formbox select.parsley-error {
  border-color: #e00000;
  background-color: #ffe8e8;
}

/* map */

.formbox__map {
  margin: 15px 0;
  height: 370px;
}
.formbox__map iframe {
  width: 100%;
  height: 100%;
}

/* table */

.formbox--table dl {
  padding: 9px 0;
}
.formbox--table dt {
  padding: 0;
  line-height: 30px;
}
.formbox--table dd {
  font-size: 16px;
  line-height: 30px;
}

/* buttons
----------------------------------------------- */

.buttons {
  display: flex;
  justify-content: center;
  margin: 80px 0 0;
}
.buttons button {
  margin: 0 15px;
}
.buttons button[type="submit"] {
  width: 580px;
}
.buttons button[type="button"] {
  width: 300px;
}

/* message
----------------------------------------------- */

.message {
  margin: 80px 0 0;
}
.message__box {
  padding: 20px 0 25px;
  border: 4px solid #000;
  box-sizing: border-box;
}
.message__title {
  margin: 0 30px;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}
.message__title--large {
  padding: 15px 0 0;
  font-size: 26px;
  text-align: center;
}
.message__text {
  margin: 0 30px;
  color: #414141;
  font-size: 18px;
  text-align: justify;
  font-weight: 700;
  line-height: 30px;
}
.message__text--light {
  margin: 10px 30px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}
.message__cautions {
  margin: 10px 30px 0;
}
.message__caution {
  padding: 0 0 0 16px;
  font-size: 16px;
  text-indent: -16px;
  line-height: 30px;
}
.message__guide {
  margin: 25px 0 0;
  font-size: 16px;
  text-align: justify;
  line-height: 30px;
}
.message__schedule {
  margin: 30px 0 0;
  background-color: #fff;
}
.message__schedule dl {
  display: flex;
  align-items: center;
  margin: 0 30px;
  padding: 35px 0;
}
.message__schedule dt {
  width: 270px;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 30px;
}
.message__schedule dd {
  width: calc(100% - 270px);
  font-size: 16px;
  line-height: 30px;
}
.message__button {
  margin: 80px auto 0;
  width: 580px;
}

/* attention */

.message__text--attention {
  color: #e00000;
  font-weight: 400;
}

/* details */

.message__details {
  margin: 35px auto 25px;
  padding: 20px 40px;
  width: 700px;
  background-color: #fff;
}
.message__details table {
  width: 100%;
}
.message__details th,
.message__details td {
  color: #414141;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  line-height: 38px;
}
.message__details th {
  width: 30%;
}
.message__details td {
  width: 70%;
}

/* category
----------------------------------------------- */

.category {
  margin: 55px 0 40px;
  background-color: #dbdada;
}
.category__lists {
  margin: 0 10px;
  padding: 40px 0;
  font-size: 0;
}
.category__list {
  display: inline-block;
  margin: 0 30px;
}
.category__list a {
  position: relative;
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
  z-index: 1;
}
.category__list a:after {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -7px;
  height: 3px;
  background-color: #bba15c;
  transform: scaleX(0);
  transform-origin: left;
}

/* current */

.category__list--current a {
  color: #bba15c;
}
.category__list--current a:after {
  transform: scaleX(1);
}

/* paging
----------------------------------------------- */

.paging {
  display: flex;
  justify-content: center;
  margin: 80px 0 0;
}
.paging__control {
  margin: 0 2px;
}
.paging__control a {
  display: block;
  position: relative;
  width: 100px;
  height: 35px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  line-height: 34px;
  border-radius: 35px;
  background-color: #000;
  z-index: 1;
}
.paging__control a:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  margin: -10px 0 0;
  width: 20px;
  height: 20px;
  background-image: url("../images/common/paging_icon.svg");
  background-repeat: no-repeat;
  background-size: 80px 20px;
  z-index: 1;
}
.paging__control--prev a {
  padding: 0 0 0 15px;
}
.paging__control--next a {
  padding: 0 15px 0 0;
}
.paging__control--prev a:after {
  left: 10px;
  background-position: -40px 0;
}
.paging__control--next a:after {
  right: 10px;
  background-position: -60px 0;
}
.paging__control--prevs a:after {
  background-position: 0 0;
}
.paging__control--nexts a:after {
  background-position: -20px 0;
}
.paging__numbers {
  margin: 0 20px;
  font-size: 0;
}
.paging__number {
  display: inline-block;
  margin: 0 2px;
}
.paging__number a {
  display: block;
  width: 35px;
  height: 35px;
  color: #fff;
  font-family: serif;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  line-height: 38px;
  border-radius: 50%;
  background-color: #000;
}

/* current*/

.paging__number--current a {
  color: #000;
  background-color: #dbdada;
}

/* features
----------------------------------------------- */

.features {
  background-color: #f0eff0;
}
.features__outer {
  margin: 0 30px;
}
.features__inner {
  margin: auto;
  padding: 70px 0 80px;
  max-width: 1200px;
}
.features__inner > *:first-child {
  padding-top: 0;
}
.features__copy {
  margin: 40px 0 0;
  font-size: 22px;
  font-weight: 700;
}
.features__columns {
  display: flex;
  flex-wrap: wrap;
  margin: -40px 0 0 -30px;
}
.features__column {
  position: relative;
  margin: 100px 0 0 30px;
  width: calc(33.33% - 30px);
  z-index: 1;
}
.features__no {
  position: absolute;
  top: -20px;
  left: 15px;
  width: 70px;
  height: 50px;
  background-image: url("../images/common/features_no.svg");
  background-repeat: no-repeat;
  background-size: 70px 300px;
  z-index: 1;
}
.features__image {
  margin: 0 30px 0 0;
  font-size: 0;
}
.features__image img {
  width: 100%;
  height: auto;
}
.features__main {
  position: relative;
  margin: -60px 0 0 30px;
  padding: 20px 0 15px;
  color: #fff;
  background-color: #000;
  box-shadow: 0 20px 18px rgba(0, 0, 0, 0.23);
  z-index: 2;
}
.features__subject {
  margin: 0 20px;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
}
.features__subject sup {
  font-size: 0.5em;
  vertical-align: middle;
}
.features__summary {
  margin: 10px 20px 0;
  min-height: 90px;
  font-size: 16px;
  line-height: 30px;
}
.features__cautions {
  margin: 60px 0 0;
}
.features__caution {
  padding: 0 0 0 30px;
  color: #414141;
  font-size: 12px;
  text-align: justify;
  text-indent: -30px;
  line-height: 18px;
}
.features__more {
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin: 40px 0 0;
  z-index: 1;
}
.features__more:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 3px;
  background-color: #000;
  z-index: -1;
}

/* anchor */

.features__main--anchor {
  padding: 25px 0 30px;
}

/* button */

.features__anchor {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -15px;
  z-index: 1;
}
.features__anchor .button {
  margin: auto;
  width: 150px;
  height: 30px;
}
.features__anchor .button:before {
  right: 5px;
  left: auto;
}
.features__anchor .button__label {
  font-size: 14px;
  letter-spacing: 0;
}

/* summary */

.features__summary--line3 {
  min-height: 90px;
}
.features__summary--line4 {
  min-height: 120px;
}

/* white */

.features--white {
  background-color: #fff;
}

/* more */

.features__more .more {
  width: 150px;
}

/* no */

.features__column:nth-child(1) .features__no {
  background-position: 0 0;
}
.features__column:nth-child(2) .features__no {
  background-position: 0 -50px;
}
.features__column:nth-child(3) .features__no {
  background-position: 0 -100px;
}
.features__column:nth-child(4) .features__no {
  background-position: 0 -150px;
}
.features__column:nth-child(5) .features__no {
  background-position: 0 -200px;
}
.features__column:nth-child(6) .features__no {
  background-position: 0 -250px;
}

/* ready */

.js-indicate-ready.features__column .features__no {
  opacity: 0;
  transform: translateY(-25px);
}
.js-indicate-ready.features__column .features__image {
  opacity: 0;
  transform: translateX(30px);
}
.js-indicate-ready.features__column .features__main {
  opacity: 0;
  transform: translateX(-30px);
}

/* start */

.js-indicate-start.features__column .features__no,
.js-indicate-start.features__column .features__image,
.js-indicate-start.features__column .features__main {
  opacity: 1;
  transform: translateX(0);
  transition-property: opacity, transform;
  transition-duration: 0.65s;
  transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
}
.js-indicate-start.features__column .features__no {
  transition-delay: 0.25s;
}
.js-indicate-start.features__column .features__main {
  transition-delay: 0.35s;
}

/* concept
----------------------------------------------- */

.concept {
}
.concept__outer {
  margin: 0 30px;
}
.concept__inner {
  margin: auto;
  padding: 50px 0 80px;
  max-width: 1200px;
}
.concept__copy {
  font-size: 22px;
  font-weight: 700;
  text-align: justify;
  line-height: 30px;
}
.concept__text {
  margin: 15px 0 0;
  font-size: 16px;
  text-align: justify;
  line-height: 30px;
}

/* merit
----------------------------------------------- */

.merit {
  margin: 40px 0 0;
  background-color: #fff;
}
.merit__inner {
  margin: 0 30px;
}
.merit__lists {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 -20px;
  padding: 10px 0 30px;
}
.merit__list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 0 20px;
  width: calc(33.33% - 20px);
  height: 100px;
  font-size: 28px;
  font-weight: 700;
  line-height: 30px;
  border: 4px solid #000;
  border-radius: 10px;
  box-sizing: border-box;
}

/* facility
----------------------------------------------- */

.facility {
  margin: 35px 0 0;
}
.facility__caption {
  margin: 20px 0 0;
  color: #414141;
  font-size: 12px;
  text-align: right;
  line-height: 18px;
}
.facility__columns {
  display: flex;
  margin: 40px 0 0 -30px;
}
.facility__column {
  margin: 0 0 0 30px;
  width: calc(33.33% - 30px);
}
.facility__title {
  font-size: 26px;
  font-weight: 700;
}
.facility__summary {
  margin: 25px 0 0;
  font-size: 16px;
  text-align: justify;
  line-height: 30px;
}

.facility__gallery {
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.facility__mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 2;
}
.facility__images {
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.facility__image {
  position: relative;
  margin: 0;
  font-size: 0;
  z-index: 1;
}
.facility__image:nth-child(n + 2) {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.facility__image img {
  width: 100%;
  height: auto;
}

/* initial */

.facility__image img {
  opacity: 0;
  transform: rotate(0.09deg) scale(1.1);
  transform-origin: center;
  transition-property: opacity, transform;
  transition-duration: 0s;
}

/* ready */

.js-facility-ready .facility__mask {
  transform-origin: right;
  transition-property: transform;
  transition-duration: 0.65s;
  transition-timing-function: cubic-bezier(0.87, 0, 0.13, 1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform;
}

/* start */

.js-facility-start .facility__mask {
  transform: scaleX(0);
  transform-origin: left;
}

/* current */

.facility__image--current img {
  opacity: 1;
  transform: rotate(0deg) scale(1);
  transition-duration: 2s;
}

/* faq
----------------------------------------------- */

.faq {
}
.faq dl {
  padding: 15px 0 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-drag: none;
  -ms-user-drag: none;
  user-drag: none;
}
.faq dt,
.faq dd {
  position: relative;
  background-color: #fff;
  z-index: 1;
}
.faq dt {
  margin: 5px 0 0;
  cursor: pointer;
}
.faq dd {
}
.faq dt:before,
.faq dt:after,
.faq dd:after {
  display: block;
  content: "";
  position: absolute;
  top: 12px;
  left: 20px;
  width: 24px;
  height: 24px;
  background-image: url("../images/common/faq_icon.svg");
  background-repeat: no-repeat;
  background-size: 72px 24px;
  z-index: 1;
}
.faq dt:before {
  left: auto;
  right: 20px;
  background-position: -48px 0;
  transition-property: transform;
  transition-duration: 0.2s;
}
.faq dd:after {
  background-position: -24px 0;
}
.faq dd:before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 20px;
  right: 60px;
  height: 1px;
  background-color: #e2e2e2;
  z-index: 1;
}
.faq dt span,
.faq dd span {
  display: block;
  padding: 8px 60px 8px 60px;
  font-size: 16px;
  line-height: 30px;
}

/* open */

.faq--open:before {
  transform: scaleY(-1);
}

/* flow
----------------------------------------------- */

.flow {
  margin: 40px 0 0;
}
.flow__lists {
  display: flex;
  margin: 0 0 0 -30px;
}
.flow__list {
  position: relative;
  margin: 0 0 0 30px;
  padding: 0 0 15px;
  background-color: #fff;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.15);
  z-index: 1;
}
.flow__list:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  right: -24px;
  margin: -15px 0 0;
  width: 16px;
  height: 30px;
  background-image: url("../images/common/flow_arrow.svg");
  background-repeat: no-repeat;
  background-size: 16px 30px;
  z-index: 2;
}
.flow__list:last-child:after {
  display: none;
}
.flow__title {
  padding: 9px 0;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  background-color: #000;
}
.flow__no {
  position: relative;
  margin: -20px 0 0 8px;
  width: 56px;
  height: 40px;
  background-image: url("../images/common/features_no.svg");
  background-repeat: no-repeat;
  background-size: 56px 240px;
  z-index: 1;
}
.flow__image {
  margin: 0;
  font-size: 0;
}
.flow__image img {
  width: 100%;
  height: auto;
}
.flow__summary {
  margin: 5px 15px 0;
  font-size: 16px;
  line-height: 30px;
}
.flow__ul {
  margin: 5px 15px 0;
}
.flow__li {
  padding: 0 0 0 16px;
  font-size: 16px;
  text-indent: -16px;
  line-height: 30px;
}
.flow em {
  color: #cba457;
  font-weight: 700;
  font-style: normal;
}

/* no */

.flow__list:nth-child(1) .flow__no {
  background-position: 0 0;
}
.flow__list:nth-child(2) .flow__no {
  background-position: 0 -40px;
}
.flow__list:nth-child(3) .flow__no {
  background-position: 0 -80px;
}
.flow__list:nth-child(4) .flow__no {
  background-position: 0 -120px;
}

/* titlebar
----------------------------------------------- */

.titlebar {
  margin: 30px 0 0;
  padding: 8px 0 10px;
  background-color: #000;
}
.titlebar__text {
  color: #fff;
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  line-height: 30px;
}

/* guideline
----------------------------------------------- */

.guideline {
  margin: 40px 0 0;
  background-color: #414141;
}
.guideline__inner {
  margin: 0 30px;
  padding: 30px 0;
  color: #fff;
}
.guideline__title {
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
}
.guideline__list {
  margin: 15px 0 0 25px;
}
.guideline__list li {
  display: list-item;
  font-size: 16px;
  line-height: 30px;
  list-style: decimal;
}

/* slidegallery
----------------------------------------------- */

.slidegallery {
  position: relative;
  margin: 40px 0 10px;
  z-index: 1;
  vertical-align: top;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-drag: none;
  -ms-user-drag: none;
  user-drag: none;
}
.slidegallery__inner {
  overflow: hidden;
}
.slidegallery__lists {
}
.slidegallery__list {
}
.slidegallery__image {
  position: relative;
  margin: 0;
  font-size: 0;
  z-index: 1;
}
.slidegallery__image img {
  width: 100%;
  height: auto;
}
.slidegallery__caption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 5px 10px;
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 1;
}
.slidegallery__arrow {
  position: absolute;
  top: 50%;
  margin: -15px 0 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 2;
}
.slidegallery__arrow--prev {
  left: -15px;
}
.slidegallery__arrow--next {
  right: -15px;
}
.slidegallery__arrow:before,
.slidegallery__arrow:after {
  display: block;
  content: "";
  position: absolute;
  z-index: 1;
}
.slidegallery__arrow:before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  background-color: #000;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
.slidegallery__arrow:after {
  top: 50%;
  left: 50%;
  margin: -6px 0 0 0;
  width: 7px;
  height: 12px;
  background-image: url("../images/common/slidegallery_arrow.svg");
  background-repeat: no-repeat;
  background-size: 14px 12px;
}
.slidegallery__arrow--prev:after {
  margin-left: -4px;
  background-position: 0 0;
}
.slidegallery__arrow--next:after {
  margin-left: -3px;
  background-position: -7px 0;
}

/* topicbox
----------------------------------------------- */

.topicbox {
}
.topicbox__lists {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 -30px;
  padding: 10px 0 0;
}
.topicbox__list {
  margin: 30px 0 0 30px;
}
.topicbox__label {
  padding: 5px 0 7px;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  line-height: 29px;
  background-color: #000;
}
.topicbox__image {
  margin: 0;
  font-size: 0;
  background-color: #f0eff0;
}
.topicbox__image img {
  width: 100%;
  height: auto;
}

/* column */

.topicbox--column2 .topicbox__list {
  width: calc(50% - 30px);
}
.topicbox--column3 .topicbox__list {
  width: calc(33.33% - 30px);
}
.topicbox--column4 .topicbox__list {
  width: calc(25% - 30px);
}

/* openstep
----------------------------------------------- */

.openstep {
}
.openstep__lists {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 -30px;
}
.openstep__list {
  position: relative;
  margin: 30px 0 0 30px;
  width: calc(25% - 30px);
  z-index: 1;
}
.openstep__list:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  right: -24px;
  margin: -15px 0 0;
  width: 16px;
  height: 30px;
  background-image: url("../images/common/flow_arrow.svg");
  background-repeat: no-repeat;
  background-size: 16px 30px;
  z-index: 1;
}
.openstep__list:last-child:after {
  display: none;
}
.openstep__label {
  padding: 0 0 5px;
  color: #cba457;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  border: 4px solid #000;
  border-bottom: 0;
  border-radius: 10px 10px 0 0;
  background-color: #000;
  box-sizing: border-box;
}
.openstep__text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px 4px;
  height: 70px;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  line-height: 25px;
  border: 4px solid #000;
  border-top: 0;
  border-radius: 0 0 10px 10px;
  background-color: #fff;
  box-sizing: border-box;
}

/* last */

.openstep__list:last-child .openstep__text {
  padding: 0 5px;
  color: #fff;
  font-size: 30px;
  border-color: #bba15c;
  background-color: #bba15c;
}

/* language
----------------------------------------------- */

.language {
  margin: 40px 0 0;
  background-color: #dbdada;
}
.language__inner {
  display: flex;
  align-items: center;
  margin: 0 50px;
  height: 100px;
}
.language__label {
  position: relative;
  width: 260px;
  font-size: 24px;
  font-weight: 700;
  text-indent: 42px;
  z-index: 1;
}
.language__label:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  margin: -21px 0 0;
  width: 32px;
  height: 43px;
  background-image: url("../images/common/icon_world.svg");
  background-repeat: no-repeat;
  background-size: 32px 43px;
  z-index: 1;
}
.language__lists {
  display: flex;
  align-items: center;
  margin: 0 0 0 -10%;
  width: calc(100% - 260px);
}
.language__list {
  margin: 0 0 0 10%;
}
.language__list a {
  display: block;
  position: relative;
  padding: 0 0 10px;
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
  white-space: nowrap;
  z-index: 1;
}
.language__list a:after {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 3px;
  background-color: #bba15c;
  z-index: 1;
  transform: scaleX(0);
}
.language__select {
  display: none;
}

/* current */

.language__list--current a {
  color: #bba15c;
}
.language__list--current a:after {
  transform: scaleX(1);
}

/* pageup
----------------------------------------------- */

.pageup {
  position: fixed;
  right: 20px;
  bottom: 25px;
  z-index: 4;
  will-change: position;
}
.pageup__button {
  position: relative;
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 1;
}
.pageup__button:before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  background-color: #bba15c;
  box-shadow: 0 7px 7px rgba(149, 134, 111, 0.24);
  box-sizing: border-box;
  z-index: 1;
}
.pageup__button:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px 0 0 -10px;
  width: 20px;
  height: 20px;
  background-image: url("../images/common/icon_arrow_top.svg");
  background-repeat: no-repeat;
  background-size: 20px 20px;
  z-index: 1;
}

/* initial */

.pageup {
  transform: translateX(80px);
}

/* ready */

.js-pageup-ready {
  transition-property: transform;
  transition-duration: 0.65s;
  transition-timing-function: cubic-bezier(0.76, 0, 0.24, 1);
}

/* open */

.js-pageup-open {
  transform: translateX(0);
}

/* fix */

.js-pageup-fix {
  position: absolute;
  bottom: 75px;
}

/* shopdetail */

.current--shopdetail .pageup {
  bottom: 115px;
}
.current--shopdetail .js-pageup-fix {
  bottom: 165px;
}

/* contactbox
----------------------------------------------- */

.contactbox {
  background-color: #dbdada;
}
.contactbox__outer {
  margin: 0 30px;
}
.contactbox__inner {
  margin: auto;
  padding: 0 0 90px;
  max-width: 1200px;
}
.contactbox__columns {
  display: flex;
  justify-content: space-between;
  margin: 40px 0 0;
}
.contactbox__column {
  padding: 25px 0;
  width: calc(50% - 15px);
  background-color: #fff;
}
.contactbox__label {
  font-size: 26px;
  font-weight: 700;
  text-align: center;
}
.contactbox__tel {
  margin: 20px 0 0;
  color: #bba15c;
  font-size: 48px;
  font-weight: 700;
  text-align: center;
}
.contactbox__tel:before {
  display: inline-block;
  content: "";
  margin: -15px -5px 0 0;
  width: 58px;
  height: 58px;
  text-align: center;
  vertical-align: middle;
  background-image: url("../images/common/icon_tel.svg");
  background-repeat: no-repeat;
  background-size: 58px 58px;
}
.contactbox__button {
  margin: 25px auto 0;
  max-width: 380px;
}

/* morebox
----------------------------------------------- */

.morebox {
  margin: 40px 0 0;
  border: 4px solid #000;
  box-sizing: border-box;
}
.morebox__columns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 26px;
  padding: 26px 0;
}
.morebox__column {
  width: 50%;
}
.morebox__column:last-child {
  display: flex;
  justify-content: flex-end;
}
.morebox__text {
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
}
.morebox__button {
  width: 320px;
}

/* anchorbox
----------------------------------------------- */

.anchorbox {
  margin: 60px 0 0;
}
.anchorbox__lists {
  display: flex;
  justify-content: space-around;
  margin: 0 0 0 -23px;
}
.anchorbox__list {
  margin: 0 0 0 23px;
  width: 100%;
}

/* medalbox
----------------------------------------------- */

.medalbox {
  margin: 30px 0;
}
.medalbox__lists {
  display: flex;
  justify-content: center;
  margin: 0 0 0 -67px;
}
.medalbox__list {
  margin: 0 0 0 67px;
  max-width: 226px;
}
.medalbox__list img {
  width: 100%;
  height: auto;
}

/* flowbox
----------------------------------------------- */

.flowbox {
}
.flowbox__lists {
}
.flowbox__list {
  margin: 40px 0 0;
  padding: 0 0 30px;
  min-height: 190px;
  background-color: #fff;
}
.flowbox__label {
  padding: 8px 0;
  color: #bba15c;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  background-color: #000;
}
.flowbox__header {
  position: relative;
  margin: 0 40px 0 190px;
  padding: 40px 0 10px;
  z-index: 1;
}
.flowbox__icon {
  position: absolute;
  top: 30px;
  left: -155px;
  z-index: 1;
}
.flowbox__subject {
  font-size: 26px;
  font-weight: 700;
  line-height: 30px;
}
.flowbox__body {
  margin: 0 40px 0 190px;
}
.flowbox__body p {
  margin: 10px 0 0;
  font-size: 16px;
  text-align: justify;
  line-height: 28px;
}
.flowbox__body p small {
  display: block;
  padding: 0 0 0 12px;
  color: #414141;
  font-size: 12px;
  text-indent: -12px;
  line-height: 18px;
}
.flowbox__case {
  margin: 20px 40px 0 190px;
  padding: 20px;
  background-color: #efefed;
}
.flowbox__case dt {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}
.flowbox__case dd {
  font-size: 16px;
  line-height: 28px;
}

/* step */

.flowbox--step .flowbox__list {
  position: relative;
  z-index: 1;
}
.flowbox--step .flowbox__list:after {
  display: block;
  content: "";
  position: absolute;
  left: 50%;
  bottom: -40px;
  margin: 0 0 0 -3px;
  width: 6px;
  height: 40px;
  background-color: #bba15c;
  z-index: 1;
}
.flowbox--step .flowbox__list:last-child:after {
  display: none;
}

/* ready */

.flowbox--step .js-indicate-ready.flowbox__list:after {
  transform: scaleY(0);
  transform-origin: top;
  will-change: transform;
}

/* start */

.flowbox--step .js-indicate-start.flowbox__list:after {
  transform: scaleY(1);
  transition-property: transform;
  transition-duration: 0.5s;
  transition-delay: 0.5s;
  transition-timing-function: cubic-bezier(0.76, 0, 0.24, 1);
}

/* philosophy
----------------------------------------------- */

.philosophy {
}
.philosophy__lists {
}
.philosophy__list {
  position: relative;
  margin: 65px 0 0 40px;
  background-color: #bba15c;
  z-index: 1;
}
.philosophy__list:after {
  display: block;
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  border: 2px solid #fff;
  box-sizing: border-box;
  pointer-events: none;
  z-index: 1;
}
.philosophy__label {
  position: absolute;
  top: -25px;
  left: -40px;
  padding: 0 20px;
  height: 52px;
  color: #cba457;
  font-size: 24px;
  font-weight: 700;
  line-height: 50px;
  background-color: #000;
  z-index: 2;
}
.philosophy__main {
  margin: 0 60px;
  padding: 45px 0;
}
.philosophy__text {
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 35px;
}
.philosophy__point {
  margin: 15px 0 0;
  counter-reset: number;
}
.philosophy__point li {
  position: relative;
  padding: 0 0 0 34px;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 39px;
  z-index: 1;
}
.philosophy__point li:before {
  display: inline-block;
  content: counter(number);
  position: absolute;
  top: 7px;
  left: 0;
  width: 26px;
  height: 26px;
  font-size: 21px;
  text-align: center;
  text-indent: 0.1em;
  vertical-align: top;
  line-height: 25px;
  border-radius: 50%;
  background-color: #fff;
  counter-increment: number;
}
.philosophy__number {
  counter-reset: number;
}
.philosophy__number li {
  padding: 0 0 0 34px;
  font-size: 28px;
  font-weight: 700;
  text-indent: -34px;
  letter-spacing: 0.05em;
  line-height: 39px;
}
.philosophy__number li:before {
  display: inline;
  content: counter(number) ". ";
  counter-increment: number;
}

/* signature
----------------------------------------------- */

.signature {
  margin: 25px 0 0;
  text-align: right;
}
.signature__post,
.signature__name {
  display: block;
  font-size: 20px;
  font-weight: 700;
}
.signature__name {
  margin: 15px 0 0;
  font-size: 22px;
}

/* mapicon
----------------------------------------------- */

.mapicon__location {
  display: inline-block;
  width: 29px;
  height: 29px;
  border-radius: 8px;
  background-image: url("../images/common/map_icon_location.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 16px;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

/* infowindow
----------------------------------------------- */

.infowindow {
  position: relative;
  width: 238px;
  z-index: 1;
}
.infowindow__inner {
  position: relative;
  padding: 8px 0;
  background-color: #fff;
  overflow: hidden;
  z-index: 2;
}
.infowindow__name {
  margin: 0 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}
.infowindow__address {
  margin: 5px 8px 0;
  font-size: 12px;
  line-height: 18px;
}
.infowindow__columns {
  display: flex;
  justify-content: space-between;
  margin: 8px 8px 0;
}
.infowindow__recruits {
  display: flex;
  margin: 0 0 0 -5px;
  width: calc(100% - 138px);
}
.infowindow__recruit {
  margin: 0 0 0 5px;
  width: 28px;
  height: 22px;
  border: 1px solid #000;
  border-radius: 4px;
  box-sizing: border-box;
}
.infowindow__recruit span {
  display: block;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  line-height: 20px;
}
.infowindow__recruit--parttime {
  color: #890000;
  border-color: #890000;
}
.infowindow__recruit--fulltime {
  color: #002f88;
  border-color: #002f88;
}
.infowindow__button {
  width: 138px;
}
.infowindow__button .button {
  height: 30px;
}
.infowindow__button .button:before {
  right: 8px;
  margin: -9px 0 0;
  width: 18px;
  height: 18px;
  background-position: -90px 0;
  background-size: 198px 36px;
}
.infowindow__button .button__label {
  font-size: 14px;
}

/* pc
----------------------------------------------- */

@media print, screen and (min-width: 1000px) {
  /* body
	----------------------------------------------- */

  body {
  }

  /* link
	----------------------------------------------- */

  .link:hover {
    opacity: 0.6;
  }

  /* click to call */

  .link--tel {
    pointer-events: none;
  }

  /* br
	----------------------------------------------- */

  .sp {
    display: none;
  }

  /* button
	----------------------------------------------- */

  /* large */

  .button--large {
    height: 60px;
  }
  .button--large:after {
    border-radius: 60px;
  }
  .button--large .button__label {
    font-size: 20px;
  }

  .button:after,
  .button__label {
    transition-property: background-color, transform;
    transition-duration: 0.2s;
    transform-origin: center;
    will-change: transform;
  }

  /* hover */

  .button:hover:after {
    border-color: #414141;
    background-color: #414141;
    transform: scale(1.025, 1.07);
  }
  .button--gold:hover:after {
    border-color: #bba15c;
    background-color: #bba15c;
  }
  .button--goldline:hover:after {
    border-color: #bba15c;
    background-color: #bba15c;
  }
  .button--goldline:hover .button__label {
    color: #fff;
  }
  .button--blackline:hover:after {
    background-color: #fff;
  }
  .button--redline:hover:after {
    border-color: #890000;
    background-color: #890000;
  }
  .button--redline:hover .button__label {
    color: #fff;
  }
  .button--blueline:hover:after {
    border-color: #002f87;
    background-color: #002f87;
  }
  .button--blueline:hover .button__label {
    color: #fff;
  }
  .button--white:hover:after {
    border-color: #dbdada;
    background-color: #dbdada;
  }

  /* redline */

  .button--redline:hover:before {
    background-position: -216px 0;
  }

  /* blueline */

  .button--blueline:hover:before {
    background-position: -240px 0;
  }

  /* white */

  .button--white:after {
    border-color: #fff;
    background-color: #fff;
  }
  .button--white.button--anchor:before {
    background-position: -144px -24px;
  }
  .button--white .button__label {
    color: #000;
  }

  /* more
	----------------------------------------------- */

  .more:after,
  .more__label:after {
    transition-property: border-color, background-color, transform;
    transition-duration: 0.25s;
  }

  /* hover */

  .more:hover:after {
    border-color: #414141;
    background-color: #414141;
    transform: rotate(0.09deg) scale(1.025, 1.1);
  }
  .more:hover .more__label:after {
    transform: translateX(3px);
  }

  /* white */

  .more--white:hover:after {
    border-color: #efefed;
    background-color: #efefed;
  }

  /* header
	----------------------------------------------- */

  /* ready */

  .js-header-ready,
  .js-header-ready .header__inner {
    transition-property: box-shadow, transform;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
  }

  /* close */

  .js-header-close {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    transform: translateY(-113px);
    transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
  }
  .js-header-close .header__inner {
    transform: translateY(56px);
    transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
  }

  /* footer
	----------------------------------------------- */

  .footer__list a {
    position: relative;
    transition-property: opacity;
    transition-duration: 0.25s;
    z-index: 1;
  }
  .footer__list a:after {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    height: 1px;
    background-color: #fff;
    z-index: 1;
    transform: scaleX(0);
    transform-origin: right;
    transition-property: transform;
    transition-duration: 0.25s;
  }

  /* hover */

  .footer__list a:hover {
    opacity: 0.6;
  }
  .footer__list a:hover:after {
    transform: scaleX(1);
    transform-origin: left;
  }

  /* menu
	----------------------------------------------- */

  .menu {
    display: none;
  }

  /* nav
	----------------------------------------------- */

  .nav__list a {
    transition-property: color;
    transition-duration: 0.15s;
  }

  /* hover */

  .nav__list a:hover {
    color: #bba15c;
  }

  /* current */

  .current--top .nav__list--top a,
  .current--shop .nav__list--shop a,
  .current--about .nav__list--about a,
  .current--faq .nav__list--faq a,
  .current--join .nav__list--join a,
  .current--contact .nav__list--contact a,
  .current--news .nav__list--news a,
  .current--franchise .nav__list--franchise a,
  .current--corporatemember .nav__list--corporatemember a {
    color: #bba15c;
  }

  /* sitemap
	----------------------------------------------- */

  .sitemap__list a,
  .sitemap__list a:before,
  .sitemap__list a:after {
    transition-property: opacity, transform;
    transition-duration: 0.25s;
  }

  .sitemap__list a:after {
    display: block;
    content: "";
    position: absolute;
    left: 20px;
    right: 0;
    bottom: -5px;
    height: 1px;
    background-color: #000;
    z-index: 1;
    transform: scaleX(0);
    transform-origin: right;
  }

  /* hover */

  .sitemap__list a:hover {
    opacity: 0.6;
  }
  .sitemap__list a:hover:before {
    transform: translateX(3px);
  }
  .sitemap__list a:hover:after {
    transform: scaleX(1);
    transform-origin: left;
  }

  /* guidance
	----------------------------------------------- */

  .js-indicate-start.guidance__column:nth-child(1) {
    transition-delay: 0.05s;
  }
  .js-indicate-start.guidance__column:nth-child(2) {
    transition-delay: 0.1s;
  }

  /* newsindex
	----------------------------------------------- */

  .newsindex__list a {
    transition-property: background-color, transform;
    transition-duration: 0.25s;
    will-change: transform;
  }

  /* hover */

  .newsindex__list a:hover {
    background-color: #5f5f5f;
    transform: translateY(-3px);
  }

  /* start */

  .js-indicate-start.newsindex__list:nth-child(odd) {
    transition-delay: 0.05s;
  }
  .js-indicate-start.newsindex__list:nth-child(even) {
    transition-delay: 0.1s;
  }

  /* location
	----------------------------------------------- */

  .location__text a:hover {
    text-decoration: underline;
  }

  /* topics
	----------------------------------------------- */

  .topics__image a:after {
    transition-property: background-color, transform;
    transition-duration: 0.2s;
  }

  /* hover */

  .topics__image a:hover:after {
    background-color: #414141;
    transform: rotate(0.09deg) scale(1.1);
  }

  /* sns
	----------------------------------------------- */

  .sns__list a {
    transition-property: background-color, transform;
    transition-duration: 0.2s;
  }

  /* hover */

  .sns__list a:hover {
    background-color: #414141;
    transform: rotate(0.09deg) scale(1.1);
  }

  /* modal
	----------------------------------------------- */

  .modal__close {
    transition-property: background-color, transform;
    transition-duration: 0.2s;
  }

  /* hover */

  .modal__close:hover {
    background-color: #414141;
    transform: rotate(0.09deg) scale(1.1);
  }

  /* formbox
	----------------------------------------------- */

  /* hover */

  .formbox textarea:hover,
  .formbox input[type="text"]:hover,
  .formbox input[type="tel"]:hover,
  .formbox input[type="email"]:hover {
    border-color: #bba15c;
  }

  /* category
	----------------------------------------------- */

  .category__list a {
    transition-property: color;
    transition-duration: 0.2s;
  }

  /* hover */

  .category__list a:hover {
    color: #bba15c;
  }

  /* paging
	----------------------------------------------- */

  .paging a {
    transition-property: background-color;
    transition-duration: 0.2s;
  }

  /* hover */

  .paging a:hover {
    background-color: #414141;
  }
  .paging__number--current a:hover {
    color: #000;
    background-color: #dbdada;
  }

  /* faq
	----------------------------------------------- */

  .faq dt span {
    transition-property: opacity;
    transition-duration: 0.2s;
  }

  /* hover */

  .faq dt:hover span {
    opacity: 0.6;
  }

  /* slidegallery
	----------------------------------------------- */

  .slidegallery__arrow:before {
    transition-property: background-color, transform;
    transition-duration: 0.2s;
  }

  /* hover */

  .slidegallery__arrow:hover:before {
    background-color: #414141;
    transform: rotate(0.09deg) scale(1.1);
  }

  /* openstep
	----------------------------------------------- */

  .openstep__list:nth-child(4n):after {
    display: none;
  }

  /* language
	----------------------------------------------- */

  .language__list a {
    transition-property: color;
    transition-duration: 0.2s;
  }

  /* hover */

  .language__list a:hover {
    color: #bba15c;
  }

  /* pageup
	----------------------------------------------- */

  .pageup__button:before {
    transition-property: background-color, transform;
    transition-duration: 0.2s;
  }

  /* hover */

  .pageup__button:hover:before {
    background-color: #cfb570;
    transform: rotate(0.095deg) scale(1.1);
  }

  /* retina
	----------------------------------------------- */

  @media only screen and (-webkit-min-device-pixel-ratio: 2) {
  }
}

/* mobile
----------------------------------------------- */

@media only screen and (max-width: 999px) {
  /* body
	----------------------------------------------- */

  body {
  }

  /* br
	----------------------------------------------- */

  .pc {
    display: none;
  }

  /* button
	----------------------------------------------- */

  .button {
    height: 50px;
  }
  .button:after {
    border-radius: 50px;
  }
  .button__label {
    font-size: 18px;
  }

  /* redline */

  .button--redline {
    height: 40px;
  }
  .button--redline .button__label {
    font-size: 15px;
  }

  /* blueline */

  .button--blueline {
    height: 40px;
  }
  .button--blueline .button__label {
    font-size: 15px;
  }

  /* reset */

  .button__reset {
    display: none;
  }

  /* header
	----------------------------------------------- */

  .header {
    box-shadow: none;
    width: 120px;
    right: 0;
    margin: auto;
  }
  .header__inner a {
    width: 120px;
    height: 64px;
    background: #000;
    justify-content: center;
    align-items: center;
  }
  .header__copy {
    display: none;
  }
  .header__logo {
    margin: 0;
  }
  .header__logo img {
    width: auto;
    height: 22px;
  }

  /* footer
	----------------------------------------------- */

  .footer__outer {
    margin: 0;
  }
  .footer__inner {
    display: block;
    padding: 30px 0;
    max-width: initial;
    height: auto;
  }
  .footer__lists {
    text-align: center;
  }
  .footer__list:nth-child(n + 2) {
    margin: 0;
    padding: 0;
  }
  .footer__list:nth-child(n + 2):after {
    display: none;
  }
  .footer__list:nth-child(3) {
    margin: 8px 0 0;
    width: 100%;
  }
  .footer__copyright {
    margin: 20px 0 0;
    font-size: 13px;
    text-align: center;
  }

  /* menu
	----------------------------------------------- */

  .menu {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 64px;
    background-color: #000;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    z-index: 5;
  }
  .menu__lists {
    font-size: 0;
    width: 100%;
    order: 2;
    display: flex;
    justify-content: flex-end;
  }
  .menu__lists li:first-child {
    margin: 0 auto 0 0;
  }
  .menu__list {
    display: inline-block;
  }
  .menu__list a {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    text-align: center;
    text-decoration: none;
    color: #000;
    font-size: 12px;
    font-weight: 700;
    line-height: 17px;
    font-family: "Noto Sans JP", sans-serif;
  }
  .menu__list a._login {
    color: #fff;
    background: #000;
  }
  .menu__list a._search {
    color: #fff;
    background: #C5A563;
  }
  .menu__list a._join {
    color: #fff;
    background: #C80000;
  }
  .menu__list span {
    display: block;
    text-align: center;
  }
  .menu__icon {
    padding: 8px 0 0;
  }
  .menu__label {
    margin: 5px 0 0;
    font-size: 8px;
    font-weight: 700;
  }
  .menu__control {
    position: relative;
    width: 64px;
    min-width: 64px;
    height: 64px;
    cursor: pointer;
    z-index: 1;
    background: #000;
  }
  .menu__open {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: "Noto Sans JP", sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 17px;
  }
  .menu__open svg {
    margin: 6px 0;
  }

  /* initial */

  .menu__close:after {
    transform: rotate(0.09deg) scale(0);
  }

  /* ready */

  .js-menu-ready .menu__open:after,
  .js-menu-ready .menu__close:after {
    transition-property: transform;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
  }

  /* open */

  .js-menu-open .menu__open:after {
    transform: scaleX(0);
  }
  .js-menu-open .menu__close:after {
    transform: rotate(0deg) scale(1);
  }

  /* nav
	----------------------------------------------- */

  .nav {
    top: 64px;
    bottom: 0;
    height: auto;
    background-color: #fff;
    box-shadow: none;
  }
  .nav__outer {
    margin: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  .nav__inner {
    display: block;
    padding: 30px 0 50px;
  }
  .nav__covid {
    margin: auto;
  }
  .nav__lists {
    margin: 0 18px;
    padding: 20px 0 0;
    display: block;
  }
  .nav__lists._main {
    margin: 0 18px;
    padding: 0;
  }
  .nav__list {
    display: block;
    margin: 0;
    border-bottom: 2px solid #000;
  }
  .nav__list a {
    position: relative;
    padding: 15px 0 15px 25px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.02em;
    z-index: 1;
    text-align: left;
  }
  .nav__list a:after {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    left: 5px;
    margin: -5px 0 0;
    width: 10px;
    height: 10px;
    background-image: url("../images/common/nav_arrow.svg");
    background-repeat: no-repeat;
    background-size: 10px;
    z-index: 1;
  }
  .nav__infos {
    display: block;
    margin: 0 18px;
    padding: 20px 0 0;
  }
  .nav__info {
    margin: 10px 0 0;
  }
  .nav__info a {
    display: inline-block;
    position: relative;
    padding: 0 0 0 20px;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    letter-spacing: 0.02em;
    z-index: 1;
  }
  .nav__info a:after {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    left: 5px;
    margin: -4px 0 0;
    width: 8px;
    height: 8px;
    background-image: url("../images/common/nav_arrow.svg");
    background-repeat: no-repeat;
    background-size: 8px;
    z-index: 1;
  }

  /* side */

  .nav__side {
    display: none;
  }

  /* initial */

  .nav {
    pointer-events: none;
    visibility: hidden;
    transform: translateX(100%);
  }

  /* ready */

  .js-nav-ready {
    transition-property: visibility, transform;
    transition-duration: 0s, 0.5s;
    transition-delay: 0.5s, 0s;
    transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
  }

  /* open */

  .js-nav-open {
    pointer-events: auto;
    visibility: visible;
    transform: translate(0);
    transition-delay: 0s;
  }

  /* sitemap
	----------------------------------------------- */

  .sitemap__outer {
    margin: 0;
  }
  .sitemap__inner {
    max-width: initial;
  }
  .sitemap__columns {
    display: block;
    padding: 20px 0;
    text-align: center;
  }
  .sitemap__column:first-child {
    width: auto;
  }
  .sitemap__column:last-child {
    display: none;
  }
  .sitemap__copy {
    margin: 5px 0 0;
  }

  /* main
	----------------------------------------------- */

  .main {
    margin: 64px 0 0;
  }

  /* quickseach
	----------------------------------------------- */

  .quickseach {
    box-shadow: none;
  }
  .quickseach__label {
    top: -15px;
    padding: 0 30px;
    height: 32px;
    font-size: 14px;
    line-height: 31px;
  }
  .quickseach__columns {
    display: block;
    margin: 0 10px;
    padding: 30px 0;
  }
  .quickseach__location {
    margin: 0;
    width: auto;
  }
  .quickseach__box {
    margin: 15px 0 0;
    width: auto;
  }
  .quickseach__area {
    margin: 15px 0 0;
    width: auto;
  }

  /* single */

  .quickseach--single .quickseach__columns {
    padding: 10px 0;
  }

  /* spot */

  .quickseach--spot {
    margin: 20px 0 0;
  }

  /* maparea */

  .quickseach--maparea {
    top: 10px;
    left: 40px;
    right: 40px;
  }
  .quickseach--maparea .quickseach__field {
    height: 40px;
  }

  /* title
	----------------------------------------------- */

  .title__label img {
    width: auto;
    height: 65px;
  }
  .title__text {
    margin: -5px 0 0;
    font-size: 14px;
  }

  /* newsindex
	----------------------------------------------- */

  .newsindex__lists {
    display: block;
    margin: 0;
    padding: 15px 0 0;
  }
  .newsindex__list {
    margin: 10px 0 0;
    width: auto;
  }
  .newsindex__list a {
    padding: 6px 0;
  }
  .newsindex__image {
    margin: 0 0 0 6px;
    width: calc(28.23% - 6px);
  }
  .newsindex__main {
    margin: 0 6px 0 12px;
    width: calc(71.77% - 18px);
  }
  .newsindex__header {
    padding: 4px 0 0;
  }
  .newsindex__date {
    margin: 3px 0 0;
    width: 75px;
    font-size: 14px;
  }
  .newsindex__categories {
    margin: 0 0 0 5px;
    width: calc(100% - 75px);
    font-size: 0;
  }
  .newsindex__category {
    width: 66px;
    height: 12px;
    font-size: 6px;
    line-height: 11px;
  }
  .newsindex__subject {
    margin: 8px 0 0;
    font-size: 14px;
    line-height: 26px;
  }
  .newsindex__more {
    margin: 20px 0 0;
  }

  /* guidance
	----------------------------------------------- */

  .guidance__outer {
    margin: 0 10px;
  }
  .guidance__inner {
    padding: 15px 0 30px;
    max-width: initial;
  }
  .guidance__columns {
    display: block;
  }
  .guidance__column {
    margin: 15px 0 0;
    width: auto;
    height: auto;
  }
  .guidance__title {
    font-size: 20px;
  }
  .guidance__text {
    margin: 10px 0 0;
    padding: 0;
    font-size: 14px;
    line-height: 26px;
  }
  .guidance__more {
    margin: 15px 0 0;
  }

  /* cover
	----------------------------------------------- */

  .cover:after {
    height: 80px;
  }
  .cover__outer {
    margin: 0 10px;
  }
  .cover__inner {
    padding: 48px 0 0;
    max-width: initial;
  }
  .cover__title {
    font-size: 28px;
    text-indent: 0;
    letter-spacing: 0;
    line-height: 32px;
  }

  /* wide */

  .cover--wide:after {
    height: 200px;
  }
  .cover--wide .cover__inner {
    padding: 170px 0 0;
  }

  /* bg */

  .cover--bg {
    margin: 0;
  }
  .cover--bg:after {
    height: 60px;
  }
  .cover--bg .cover__inner {
    padding: 60px 0 0;
  }

  /* image */

  .cover--shop:after {
    background-image: url("../images/common/cover_image_shop_sp@2x.jpg");
  }
  .cover--contact:after {
    background-image: url("../images/common/cover_image_contact_sp@2x.png");
  }
  .cover--news:after {
    background-image: url("../images/common/cover_image_news_sp@2x.jpg");
  }
  .cover--about:after {
    background-image: url("../images/common/cover_image_about_sp@2x.jpg");
  }
  .cover--admission:after {
    background-image: url("../images/common/cover_image_admission_sp@2x.jpg");
  }
  .cover--franchise:after {
    background-image: url("../images/common/cover_image_franchise_sp@2x.jpg");
  }
  .cover--company:after {
    background-image: url("../images/common/cover_image_company_sp@2x.jpg");
  }
  .cover--corporatemember:after {
    background-image: url("../images/common/cover_image_corporatemember_sp@2x.jpg");
  }

  /* franchise */

  .cover--franchise .cover__inner,
  .cover--corporatemember .cover__inner,
  .cover--recruit .cover__inner {
    padding: 0;
    height: 200px;
  }

  /* location
	----------------------------------------------- */

  .location {
    display: none;
  }

  /* content
	----------------------------------------------- */

  .content__outer {
    margin: 0 10px;
  }
  .content__inner {
    padding: 0 0 30px;
    max-width: initial;
  }
  .content__title {
    margin: 15px 0 0;
    font-size: 26px;
    line-height: 30px;
  }
  .content__h2 {
    padding: 40px 0 15px;
    font-size: 20px;
    line-height: 25px;
  }
  .content__h3 {
    margin: 30px 0 0;
    font-size: 16px;
    line-height: 30px;
  }
  .content__h3 small {
    font-size: 12px;
  }
  .content__text {
    margin: 15px 0 0;
    font-size: 14px;
    line-height: 26px;
  }
  .content__text small {
    font-size: 11px;
  }
  .content__title + .content__text {
    margin-top: 20px;
  }
  .content__text--last {
    margin: 30px 0 0;
  }
  .content__text--small {
    margin: 10px 0 0;
    font-size: 11px;
    line-height: 18px;
  }
  .content__more {
    margin: 30px 0 0;
    width: auto;
  }
  .content__list {
    margin: 15px 0 0 25px;
  }
  .content__list li {
    font-size: 14px;
    line-height: 26px;
  }
  ul.content__list li {
  }
  ol.content__list li {
  }

  /* textbox */

  .content__textbox {
    padding: 15px 0 0;
  }
  .content__textbox p {
    margin: 5px 0 0;
  }

  /* line */

  .content__h2--line {
    padding: 40px 0 0;
  }

  /* large */

  .content__h2--large {
    padding-top: 15px;
    padding-bottom: 10px;
    font-size: 20px;
    line-height: 30px;
  }
  .content__h2--large small {
    margin: 0;
    font-size: 14px;
  }

  /* column */

  .content__columns {
    display: block;
    margin: 0;
    padding: 15px 0 0;
  }
  .content__column {
    margin: 15px 0 0;
  }
  .content__columns--2 .content__column,
  .content__columns--3 .content__column,
  .content__columns--4 .content__column,
  .content__columns--5 .content__column {
    width: auto;
  }

  /* cautions */

  .content__caution {
    padding: 0 0 0 14px;
    font-size: 14px;
    text-indent: -14px;
    line-height: 26px;
  }

  /* table */

  .content__table {
    margin: 0;
  }
  .content__table th,
  .content__table td {
    display: block;
    font-size: 14px;
    line-height: 26px;
  }
  .content__table th {
    margin: 15px 0 0;
    font-weight: bold;
  }
  .content__table td {
    padding-left: 0;
  }

  /* dl */

  .content__dl {
    margin: 15px 0 0;
  }
  .content__dl dt,
  .content__dl dd {
    font-size: 14px;
    line-height: 26px;
  }

  /* topics
	----------------------------------------------- */

  .topics {
    margin: 20px 0 0;
  }
  .topics__inner {
    display: block;
    margin: 0 10px;
    padding: 15px 0;
  }
  .topics__main {
    width: auto;
  }
  .topics__title {
    font-size: 18px;
    line-height: 30px;
  }
  .topics__date {
    margin: 10px 0 0;
    font-size: 16px;
  }
  .topics__text {
    margin: 10px 0 0;
    font-size: 14px;
    line-height: 26px;
  }
  .topics__image {
    margin: 10px 0 0;
    width: auto;
  }
  .topics__image img {
  }
  .topics__image a {
    pointer-events: none;
  }
  .topics__image a:after {
    display: none;
  }

  /* plan
	----------------------------------------------- */

  .plan__lists {
    display: block;
    margin: 0;
  }
  .plan__list {
    margin: 20px 0 0;
    padding: 0 0 10px;
    width: auto;
  }
  .plan__title {
    padding: 5px 0;
    font-size: 18px;
  }
  .plan__label {
    margin: 5px 0;
  }
  .plan__plus {
    margin: 5px 0;
  }

  .plan__table {
    margin: 3px 10px;
  }
  .plan__table th,
  .plan__table td {
    padding: 10px;
  }
  .plan__table th {
    width: 30%;
    font-size: 14px;
    line-height: 26px;
  }
  .plan__table td {
    padding: 10px;
    width: 70%;
  }

  .plan__caution {
    margin: 5px 10px 0;
  }

  /* wide */

  .plan--wide .plan__lists {
  }
  .plan--wide .plan__list {
    margin: 20px 0 0 0;
    padding: 0 0 7px;
  }
  .plan--wide .plan__title {
    margin: 0 0 10px;
  }
  .plan--wide .plan__table {
    margin: 3px 10px;
  }
  .plan--wide .plan__table th {
    width: 28%;
  }
  .plan--wide .plan__table td {
    width: 72%;
  }
  .plan--wide .plan__table td em {
    font-size: 16px;
  }
  .plan--wide .plan__table td em small {
    font-size: 12px;
  }

  /* sns
	----------------------------------------------- */

  .sns {
    margin: 40px 0 0;
    border-width: 3px;
  }
  .sns__inner {
    display: block;
    padding: 10px 0;
    text-align: center;
  }
  .sns__label {
    color: #414141;
    font-size: 16px;
  }
  .sns__lists {
    margin: 10px 0 0;
  }
  .sns__list {
    margin: 0 10px;
  }

  /* localnav
	----------------------------------------------- */

  .localnav__outer {
    margin: 0 13px;
  }
  .localnav__inner {
    max-width: initial;
  }
  .localnav__lists {
    align-items: flex-start;
    margin: 0 0 0 -10px;
    height: 96px;
  }
  .localnav__list {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 10px;
    width: calc(33.33% - 10px);
  }
  .localnav__header {
    display: block;
    margin: 4px 0 0;
    order: 2;
  }
  .localnav__label {
    display: none;
  }
  .localnav__text {
    width: auto;
    font-size: 9px;
    line-height: 10px;
    text-align: center;
  }
  .localnav__text--tel a {
    font-size: 9px;
  }
  .localnav__text--tel a:before {
    margin: 0 0 -3px;
    width: 14px;
    height: 14px;
    background-size: 14px 14px;
  }
  .localnav__more {
    display: block;
    margin: 0;
    padding: 8px 0 0;
  }
  .localnav__more:after {
    display: none;
  }

  /* more */

  .localnav__more .more {
    width: auto;
    height: 40px;
  }
  .localnav__more .more__label {
    font-size: 11px;
  }
  .localnav__more .more--icon .more__label:after {
    display: none;
  }

  /* faqguide
	----------------------------------------------- */

  .faqguide {
    margin: 25px -10px 0;
  }
  .faqguide__columns {
    display: block;
    margin: 0 10px;
    padding: 20px 0;
  }
  .faqguide__column:first-child,
  .faqguide__column:last-child {
    width: auto;
  }
  .faqguide__column:last-child {
    margin: 10px 0 0;
  }
  .faqguide__text {
    font-size: 16px;
    line-height: 30px;
  }

  /* step
	----------------------------------------------- */

  .step {
    margin: 25px -10px 0;
  }
  .step__lists {
    margin: 0 10px 0 -2px;
    padding: 20px 0;
  }
  .step__list {
    margin: 0 0 0 12px;
    width: calc(33.33% - 12px);
    z-index: 1;
  }
  .step__list:before {
    right: 10px;
  }
  .step__list:after {
    border-width: 15px 0 15px 10px;
  }
  .step__list span {
    height: 30px;
    font-size: 12px;
    line-height: 29px;
  }

  /* small */

  .step--small .step__lists {
    margin: 0 10px 0 3px;
  }
  .step--small .step__list {
    margin: 0 0 0 7px;
    width: calc(33.33% - 7px);
  }
  .step--small .step__list span {
    font-size: 14px;
  }

  /* need
	----------------------------------------------- */

  .need {
    margin: 0 4px 2px;
    width: 45px;
    height: 20px;
    font-size: 10px;
    line-height: 19px;
  }

  /* formbox
	----------------------------------------------- */

  .formbox {
    margin: 20px 0 0;
  }
  .formbox dl {
    display: block;
    margin: 0 10px;
    padding: 10px 0;
  }
  .formbox dt {
    padding: 0;
    width: auto;
    font-size: 16px;
    line-height: 26px;
  }
  .formbox dd {
    margin: 10px 0 0;
    width: auto;
    font-size: 16px;
    line-height: 26px;
  }

  .formbox .aform-name-ul {
    width: auto;
  }
  .formbox .aform-name-ul li {
    width: calc(50% - 5px);
  }
  .formbox .aform-name-ul li label {
    margin: 0 5px 0 0;
  }

  .formbox .aform-radio-ul {
    margin: 0 0 0 -30px !important;
  }
  .formbox .aform-radio-ul li {
    margin: 0 0 0 30px;
  }

  .formbox__note {
    font-size: 10px;
    line-height: 18px;
  }

  .formbox textarea,
  .formbox input[type="text"],
  .formbox input[type="tel"],
  .formbox input[type="email"] {
    width: 100%;
    font-size: 16px;
  }
  .formbox__date select {
    margin: 10px 5px 0 0;
  }
  .formbox__date label {
    display: inline-block;
    margin: 7px 5px 0 0;
    vertical-align: middle;
  }

  .formbox__date .ui-datepicker-trigger {
    display: inline-block;
    margin: 9px 0 0 -5px;
    vertical-align: middle;
  }
  /*
	.formbox__date span {
		display: inline-block;
		margin: 9px 0 0 -5px;
		vertical-align: middle;
	}
	*/
  /*
	.formbox select {
		margin: 0 2px;
		padding: 0 25px 0 10px;
		width: auto;
		background-position: right 10px center;
		background-size: 10px;
	}
	*/
  .formbox input[type="radio"] + label {
    padding: 0 0 0 30px;
  }

  /* aform */

  .aform-required {
    margin: 0 0 2px 10px;
    width: 45px;
    height: 19px;
    font-size: 10px;
    line-height: 18px;
  }
  .aform-error {
    font-size: 12px;
    line-height: 18px;
  }
  .aform-input-example {
    font-size: 12px;
    line-height: 18px;
  }

  /* table */

  .formbox__price--first {
    margin: 40px 0 0;
  }
  .formbox table {
    width: 100%;
  }
  .formbox th,
  .formbox td {
    padding: 5px 10px;
  }
  .formbox th {
    width: 50%;
  }
  .formbox td {
    width: 50%;
  }

  /* plan */

  .formbox__plan th,
  .formbox__plan td {
    display: block;
    width: auto !important;
    max-width: 100%;
    border-right: none;
  }
  .formbox__plan th {
    font-size: 16px;
    letter-spacing: 0.05em;
    line-height: 30px;
  }

  /* price */

  .formbox__price thead th {
    padding: 8px 0;
    font-size: 18px;
  }
  .formbox__price th {
    width: 55%;
    font-size: 14px;
    line-height: 20px;
  }
  .formbox__price td {
    width: 45%;
    font-size: 16px;
  }

  /* card */

  .formbox__card ul {
    display: block !important;
    padding: 0 0 10px;
  }
  .formbox__card li {
    margin-top: 15px !important;
  }

  /* agree */

  .formbox--agree label {
    margin: 0 10px 2px 0;
  }
  .formbox--agree .formbox__item {
    display: block;
    margin: 0;
    padding: 10px 0;
  }
  .formbox__column:first-child,
  .formbox__column:last-child {
    width: auto;
  }
  .formbox__text {
    margin: 5px 10px 0;
    padding: 0 0 0 30px;
    font-size: 16px;
    text-indent: -30px;
    line-height: 30px;
  }
  .formbox__column:last-child .aform-error {
    margin: 2px 0 0 40px;
  }

  /* map */

  .formbox__map {
    margin: 10px 0 5px;
    height: 140px;
  }

  /* table */

  .formbox--table dt {
    margin: 0;
    padding: 0;
  }
  .formbox--table dd {
    margin: 3px 0 0;
    padding: 0;
  }

  /* buttons
	----------------------------------------------- */

  .buttons {
    margin: 30px 0 0;
  }
  .buttons button {
    margin: 0;
  }
  .buttons button[type="submit"] {
    width: 100%;
  }

  /* confirm */

  .buttons--confirm {
    justify-content: space-between;
  }
  .buttons--confirm button[type="submit"] {
    width: calc(100% - 90px);
  }
  .buttons--confirm button[type="button"] {
    width: 80px;
    height: 30px;
  }
  .buttons--confirm button[type="button"]:before {
    left: 7px;
    margin: -10px 0 0;
    width: 20px;
    height: 20px;
    background-position: -160px -20px;
    background-size: 220px 40px;
  }
  .buttons--confirm button[type="button"] .button__label {
    padding: 0 0 0 10px;
    font-size: 14px;
  }

  /* message
	----------------------------------------------- */

  .message {
    margin: 20px 0 0;
  }
  .message__box {
    padding: 5px 0;
    border-width: 3px;
  }
  .message__title {
    margin: 0 10px;
    font-size: 16px;
    line-height: 26px;
  }
  .message__text {
    margin: 0 10px;
    font-size: 16px;
    letter-spacing: 0.05em;
    line-height: 26px;
  }
  .message__text--light {
    margin: 10px 10px 0;
    font-size: 16px;
    line-height: 26px;
  }
  .message__cautions {
    margin: 5px 10px 0;
  }
  .message__caution {
    padding: 0 0 0 16px;
    font-size: 16px;
    text-indent: -16px;
    line-height: 26px;
  }
  .message__guide {
    margin: 25px 0 0;
    font-size: 14px;
    line-height: 26px;
  }
  .message__schedule {
    margin: 25px 0 0;
  }
  .message__schedule dl {
    display: block;
    margin: 0 10px;
    padding: 10px 0;
  }
  .message__schedule dt {
    width: auto;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
  }
  .message__schedule dd {
    margin: 5px 0 0;
    width: auto;
    font-size: 16px;
    line-height: 26px;
  }
  .message__button {
    margin: 25px 0 0;
    width: auto;
  }

  /* details */

  .message__details {
    margin: 30px 10px 15px;
    padding: 5px 10px;
    width: auto;
  }
  .message__details th,
  .message__details td {
    font-size: 16px;
    line-height: 26px;
  }
  .message__details th {
    width: 45%;
  }
  .message__details td {
    width: 55%;
  }

  /* category
	----------------------------------------------- */

  .category {
    margin: 20px -10px 0;
  }
  .category__lists {
    margin: 0;
    padding: 10px 0;
  }
  .category__list {
    display: inline-block;
    margin: 10px 20px;
  }
  .category__list a {
    font-size: 18px;
  }
  .category__list a:after {
    bottom: -5px;
  }

  /* paging
	----------------------------------------------- */

  .paging {
    margin: 30px 6px 0;
  }
  .paging__control {
    margin: 0 2px;
    width: calc(25% - 4px);
  }
  .paging__control a {
    width: auto;
  }
  .paging__control--prev a {
    padding: 0 0 0 15px;
  }
  .paging__control--next a {
    padding: 0 15px 0 0;
  }
  .paging__numbers {
    display: none;
  }

  /* features
	----------------------------------------------- */

  .features__outer {
    margin: 0 10px;
  }
  .features__inner {
    padding: 30px 0;
    max-width: initial;
  }
  .features__copy {
    margin: 25px 0 0;
    font-size: 16px;
  }
  .features__columns {
    display: block;
    margin: 0;
  }
  .features__column {
    margin: 60px 0 0;
    width: auto;
  }
  .features__no {
    top: -20px;
    left: 5px;
  }
  .features__image {
    margin: 0 30px 0 0;
  }
  .features__main {
    margin: -30px 0 0 30px;
    padding: 15px 0;
  }
  .features__subject {
    margin: 0 20px;
    font-size: 20px;
    line-height: 28px;
  }
  .features__summary {
    margin: 5px 20px 0;
    min-height: initial;
    font-size: 14px;
    line-height: 26px;
  }
  .features__cautions {
    margin: 40px 0 0;
  }
  .features__more {
    margin: 25px 0 0;
  }

  /* anchor */

  .features__main--anchor {
    padding: 15px 0 30px;
  }

  /* concept
	----------------------------------------------- */

  .concept__outer {
    margin: 0 10px;
  }
  .concept__inner {
    padding: 20px 0 25px;
    max-width: initial;
  }
  .concept__copy {
    font-size: 16px;
  }
  .concept__text {
    margin: 10px 0 0;
  }

  /* merit
	----------------------------------------------- */

  .merit {
    margin: 20px 0 0;
  }
  .merit__inner {
    margin: 0 10px;
  }
  .merit__lists {
    margin: 0 0 0 -7px;
    padding: 2px 0 10px;
  }
  .merit__list {
    margin: 8px 0 0 7px;
    width: calc(50% - 7px);
    height: 58px;
    font-size: 15px;
    line-height: 18px;
    border-width: 3px;
  }

  /* facility
	----------------------------------------------- */

  .facility {
    margin: 15px 0 0;
  }
  .facility__caption {
    margin: 10px 0 0;
    font-size: 11px;
    line-height: 18px;
  }
  .facility__columns {
    display: block;
    margin: 0;
  }
  .facility__column {
    margin: 15px 0 0;
    width: auto;
  }
  .facility__title {
    font-size: 20px;
  }
  .facility__summary {
    margin: 10px 0 0;
    font-size: 14px;
    line-height: 26px;
  }

  /* faq
	----------------------------------------------- */

  .faq dt:before,
  .faq dt:after,
  .faq dd:after {
    top: 11px;
    left: 10px;
    width: 18px;
    height: 18px;
    background-size: 54px 18px;
  }
  .faq dt:before {
    left: auto;
    right: 10px;
    background-position: -36px 0;
  }
  .faq dd:after {
    background-position: -18px 0;
  }
  .faq dd:before {
    left: 10px;
    right: 30px;
  }
  .faq dt span,
  .faq dd span {
    display: block;
    padding: 6px 30px 7px 36px;
    font-size: 14px;
    line-height: 26px;
  }

  /* flow
	----------------------------------------------- */

  .flow {
    margin: 10px 0 0;
  }
  .flow__lists {
    display: block;
    margin: 0;
  }
  .flow__list {
    margin: 30px 0 0;
    padding: 0 0 30px;
  }
  .flow__list:first-child {
    margin: 10px 0 0;
  }
  .flow__list:after {
    top: auto;
    left: 50%;
    right: auto;
    bottom: -32px;
    margin: 0 0 0 -5px;
    transform: rotate(90deg);
  }
  .flow__title {
    padding: 5px 0 6px;
    font-size: 16px;
  }
  .flow__summary {
    margin: 5px 10px 0;
    font-size: 14px;
    line-height: 26px;
  }
  .flow__ul {
    margin: 5px 10px 0;
  }
  .flow__li {
    padding: 0 0 0 14px;
    font-size: 14px;
    text-indent: -14px;
    line-height: 26px;
  }

  /* titlebar
	----------------------------------------------- */

  .titlebar {
    margin: 20px 0 0;
    padding: 2px 0 5px;
  }
  .titlebar__text {
    font-size: 14px;
    line-height: 20px;
  }

  /* franchise
	----------------------------------------------- */

  .franchise__title {
    font-size: 32px;
    letter-spacing: 0;
  }
  .franchise__title small {
    font-size: 22px;
  }
  .franchise__copy {
    margin: 20px 0 0;
    font-size: 14px;
  }

  /* corporatemember
	----------------------------------------------- */

  .corporatemember__copy {
    padding: 20px 0 0;
    font-size: 14px;
  }
  .corporatemember__title {
    margin: 15px 0 0;
    font-size: 28px;
    line-height: 32px;
  }

  /* guideline
	----------------------------------------------- */

  .guideline {
    margin: 20px 0 0;
  }
  .guideline__inner {
    margin: 0 10px;
    padding: 10px 0;
  }
  .guideline__title {
    font-size: 16px;
  }
  .guideline__list {
    margin: 5px 0 0 20px;
  }
  .guideline__list li {
    font-size: 14px;
    line-height: 26px;
  }

  /* slidegallery
	----------------------------------------------- */

  .slidegallery {
    margin: 30px 0 5px;
  }
  .slidegallery__caption {
    padding: 2px 6px;
    font-size: 9px;
    line-height: 14px;
  }
  .slidegallery__arrow--prev {
    left: -10px;
  }
  .slidegallery__arrow--next {
    right: -10px;
  }

  /* topicbox
	----------------------------------------------- */

  .topicbox__lists {
    display: block;
    margin: 0;
    padding: 0;
  }
  .topicbox__list {
    margin: 10px 0 0;
    width: auto;
  }
  .topicbox__label {
    padding: 5px;
    font-size: 14px;
    line-height: 19px;
  }

  /* column */

  .topicbox--column2 .topicbox__list,
  .topicbox--column3 .topicbox__list,
  .topicbox--column4 .topicbox__list {
    width: auto;
  }

  /* openstep
	----------------------------------------------- */

  .openstep__lists {
    margin: 0 0 0 -27px;
  }
  .openstep__list {
    margin: 15px 0 0 27px;
    width: calc(50% - 27px);
  }
  .openstep__list:after {
    right: -20px;
    margin: -11px 0 0;
    width: 12px;
    height: 22px;
    background-size: 12px 22px;
  }
  .openstep__list:nth-child(even):after,
  .openstep__list:last-child:after {
    display: none;
  }
  .openstep__label {
    padding: 0 0 4px;
    font-size: 15px;
    border-width: 3px;
  }
  .openstep__text {
    padding: 0 5px 3px;
    min-height: 37px;
    height: auto;
    font-size: 13px;
    line-height: 18px;
    border-width: 3px;
  }

  /* last */

  .openstep__list:last-child .openstep__text {
    font-size: 18px;
  }

  /* language
	----------------------------------------------- */

  .language {
    margin: 15px 0 0;
  }
  .language__inner {
    margin: 0 20px;
    height: 54px;
  }
  .language__label {
    width: 150px;
    font-size: 16px;
    text-indent: 36px;
  }
  .language__label:after {
    margin: -18px 0 0;
    width: 26px;
    height: 36px;
    background-size: 24px 36px;
  }
  .language__lists {
    display: none;
  }
  .language__select {
    display: block;
    width: calc(100% - 150px);
  }
  .language__select select {
    display: block;
    margin: 0;
    padding: 0 64px 0 10px;
    width: 100%;
    height: 40px;
    font-size: 16px;
    font-weight: 500;
    vertical-align: bottom;
    line-height: normal;
    outline: none;
    border: 2px solid #000;
    border-radius: 0;
    background: transparent;
    background-image: url("../images/common/select_arrow.svg");
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 14px 14px;
    background-color: #fff;
    box-sizing: border-box;
    -webkit-appearance: none;
  }

  /* pageup
	----------------------------------------------- */

  .pageup {
    right: 10px;
    bottom: 25px;
  }

  /* fix */

  .js-pageup-fix {
    bottom: 106px;
  }

  /* shopdetail */

  .current--shopdetail .pageup {
    bottom: 115px;
  }
  .current--shopdetail .js-pageup-fix {
    bottom: 200px;
  }

  /* contactbox
	----------------------------------------------- */

  .contactbox__outer {
    margin: 0 10px;
  }
  .contactbox__inner {
    padding: 0 0 30px;
    max-width: initial;
  }
  .contactbox__columns {
    display: block;
    margin: 0;
  }
  .contactbox__column {
    margin: 10px 0 0;
    padding: 10px 0;
    width: auto;
  }
  .contactbox__label {
    font-size: 16px;
  }
  .contactbox__tel {
    margin: 15px 0 0;
    font-size: 22px;
  }
  .contactbox__tel:before {
    margin: -10px 0 0;
    width: 32px;
    height: 32px;
    background-size: 32px 32px;
  }
  .contactbox__button {
    margin: 10px auto 0;
    max-width: 260px;
  }

  /* morebox
	----------------------------------------------- */

  .morebox {
    margin: 20px 0 0;
    border-width: 3px;
  }
  .morebox__columns {
    display: block;
    margin: 0 10px;
    padding: 5px 0 7px;
  }
  .morebox__column {
    width: auto;
  }
  .morebox__column:last-child {
    display: block;
  }
  .morebox__text {
    font-size: 13px;
    text-align: center;
    line-height: 26px;
  }
  .morebox__button {
    margin: 5px auto 0;
    max-width: 300px;
    width: auto;
  }

  /* anchorbox
	----------------------------------------------- */

  .anchorbox {
    margin: 40px 0 0;
  }
  .anchorbox__inner {
    margin: 0 -10px;
    overflow: hidden;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .anchorbox__inner::-webkit-scrollbar {
    display: none;
  }
  .anchorbox__lists {
    display: block;
    margin: 0 0 0 10px;
    padding: 0 0 15px;
    font-size: 0;
    white-space: nowrap;
  }
  .anchorbox__list {
    display: inline-block;
    margin: 0 5px 0 0;
    width: 130px;
  }

  .anchorbox__list .button {
    height: 30px;
  }
  .anchorbox__list .button__label {
    padding: 0 3px 0 0;
    font-size: 11px;
  }
  .anchorbox__list .button:before {
    right: 3px;
    margin: -7px 0 0;
    width: 15px;
    height: 15px;
    background-size: 165px 30px;
  }
  .anchorbox__list .button--anchor:before {
    background-position: -91px 0;
  }

  /* medalbox
	----------------------------------------------- */

  .medalbox {
    margin: 10px 0 0;
  }
  .medalbox__lists {
    margin: 0 0 0 -7px;
  }
  .medalbox__list {
    margin: 0 0 0 7px;
    max-width: 113px;
  }

  /* flowbox
	----------------------------------------------- */

  .flowbox__list {
    margin: 20px 0 0;
    padding: 0 0 10px;
    min-height: initial;
  }
  .flowbox__label {
    padding: 8px 0;
    font-size: 14px;
  }
  .flowbox__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 10px;
    padding: 10px 0 0;
  }
  .flowbox__icon {
    position: static;
    width: 60px;
  }
  .flowbox__icon img {
    width: 100%;
    height: auto;
  }
  .flowbox__subject {
    width: calc(100% - 70px);
    font-size: 15px;
    line-height: 25px;
  }
  .flowbox__body {
    margin: 0 10px;
  }
  .flowbox__body p {
    margin: 5px 0 0;
    font-size: 14px;
    line-height: 26px;
  }
  .flowbox__body p small {
    padding: 0 0 0 11px;
    font-size: 11px;
    text-indent: -11px;
  }
  .flowbox__case {
    margin: 10px 10px 0;
    padding: 10px;
  }
  .flowbox__case dt {
    font-size: 14px;
    line-height: 24px;
  }
  .flowbox__case dd {
    font-size: 14px;
    line-height: 24px;
  }

  /* step */

  .flowbox--step .flowbox__list:after {
    bottom: -20px;
    height: 20px;
  }

  /* philosophy
	----------------------------------------------- */

  .philosophy__list {
    margin: 30px 0 0 15px;
  }
  .philosophy__list:after {
    top: 6px;
    left: 6px;
    right: 6px;
    bottom: 6px;
  }
  .philosophy__label {
    top: -18px;
    left: -15px;
    padding: 0 15px;
    height: 36px;
    font-size: 16px;
    line-height: 35px;
  }
  .philosophy__main {
    margin: 0 25px;
    padding: 25px 0;
  }
  .philosophy__text {
    font-size: 16px;
    line-height: 26px;
  }
  .philosophy__point {
    margin: 10px 0 0;
  }
  .philosophy__point li {
    padding: 0 0 0 25px;
    font-size: 15px;
    line-height: 26px;
  }
  .philosophy__point li:before {
    top: 4px;
    width: 18px;
    height: 18px;
    font-size: 13px;
    line-height: 18px;
  }
  .philosophy__number li {
    padding: 0 0 0 20px;
    font-size: 16px;
    text-indent: -20px;
    line-height: 26px;
  }

  /* signature
	----------------------------------------------- */

  .signature {
    margin: 15px 0 0;
  }
  .signature__post,
  .signature__name {
    font-size: 14px;
  }
  .signature__name {
    margin: 12px 0 0;
    font-size: 18px;
  }

  /* infowindow
	----------------------------------------------- */
  .infowindow {
    width: 100%;
  }
  .infowindow__columns {
    display: block;
  }
  .infowindow__button {
    margin-left: auto;
  }
}

/* indicate
----------------------------------------------- */

/* ready */

.js-indicate-ready.js-indicate-tpin {
  opacity: 0;
  transform: translateY(-125px);
}
.js-indicate-ready.js-indicate-btin {
  opacity: 0;
  transform: translateY(125px);
}
.js-indicate-ready.js-indicate-ltin {
  opacity: 0;
  transform: translateX(-150px);
}
.js-indicate-ready.js-indicate-rtin {
  opacity: 0;
  transform: translateX(150px);
}
.js-indicate-ready.js-indicate-zoomin {
  opacity: 0;
  transform: rotate(0.09deg) scale(0.65);
  transform-origin: center;
}
.js-indicate-ready.js-indicate-pickup {
  opacity: 0;
  transform: rotate(0.09deg) scale(1.5);
}

/* start */

.js-indicate-start.js-indicate-tpin,
.js-indicate-start.js-indicate-btin,
.js-indicate-start.js-indicate-ltin,
.js-indicate-start.js-indicate-rtin,
.js-indicate-start.js-indicate-zoomin {
  opacity: 1;
  transform: rotate(0) translate(0);
  transition-property: opacity, transform;
  transition-duration: 0.65s;
  transition-timing-function: cubic-bezier(0.65, 0, 0.35, 1),
    cubic-bezier(0.33, 1, 0.68, 1);
}
.js-indicate-start.js-indicate-ltin,
.js-indicate-start.js-indicate-rtin {
  transition-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
}
.js-indicate-start.js-indicate-zoomin {
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.65, 0, 0.35, 1),
    cubic-bezier(0.34, 1.56, 0.64, 1);
}
.js-indicate-start.js-indicate-pickup {
  animation-name: focus;
  animation-duration: 0.85s;
  animation-delay: 0.25s;
  animation-fill-mode: forwards;
}

/* keyframes
----------------------------------------------- */

@keyframes loading {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes step {
  20% {
    opacity: 0.7;
  }
}

@keyframes blink {
  5% {
    opacity: 0.6;
    transform: rotate(0.09deg) scale(1.05);
  }
  10% {
    opacity: 1;
    transform: rotate(0deg) scale(1);
  }
  15% {
    opacity: 0.6;
    transform: rotate(0.09deg) scale(1.05);
  }
  20% {
    opacity: 1;
    transform: rotate(0deg) scale(1);
  }
}

@keyframes focus {
  40% {
    opacity: 1;
    transform: rotate(0deg) scale(0.85);
  }
  100% {
    opacity: 1;
    transform: rotate(0deg) scale(1);
  }
}

/*
* 7b-k : 2022.10.10
*/
.footer__breadcrumb {
  display: none;
}
@media only screen and (max-width: 999px) {
  .footer__breadcrumb {
    display: block;
  }
  .footer__breadcrumb .location {
    display: block;
    position: static;
    padding: 5px 10px;
    background-color: white;
  }
  .footer__breadcrumb .location__text a {
    color: black;
    margin-left: 0;
  }
  .footer__breadcrumb .location__text a:after {
    margin-left: 5px;
    margin-right: 5px;
    background-position: 0 -8px;
  }
}

.formbox__underage {
  display: none;
  margin: 20px 0 0 0;
}

.input-password-block {
  position: relative;
}
.input-password-block input.input-password {
  padding: 0 40px 0 10px;
}
@media only screen and (max-width: 999px) {
  .input-password-block input.input-password {
    width: 100%;
  }
}
.password-show {
  position: absolute;
  right: 25%;
  top: 0;
  width: 40px;
  height: 40px;
  border: none;
  background: url("../images/common/password_show_off.svg") no-repeat center center;
  background-size: 20px;
}
@media only screen and (max-width: 999px) {
  .password-show {
    right: 0;
  }
}
.password-show.on {
  background: url("../images/common/password_show_on.svg") no-repeat center center;
  background-size: 20px;
}
.referral-modal-layer {
  position: fixed;
  width: 100%;
  height: calc(100% - 113px);
  top: 113px;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
@media screen and (max-width: 767px) {
  .referral-modal-layer {
    height: calc(100% - 60px);
    top: 60px;
  }
}

.iframe-modal-layer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.iframe-modal-layer-inner {
  width: 800px;
  position: relative;
  max-height: 80%;
  margin: 0;
  aspect-ratio: 4 / 5;
}
@media only screen and (max-width: 999px) {
  .iframe-modal-layer-inner {
    width: 90%;
    aspect-ratio: 4 / 6;
  }
}
.iframe-modal-layer-inner iframe {
  width: 100%;
  height: 100%;
}
.email-modal-layer {
  position: fixed;
  width: 100%;
  height: calc(100% - 113px);
  top: 113px;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
@media screen and (max-width: 767px) {
  .email-modal-layer {
    height: calc(100% - 60px);
    top: 60px;
  }
}
.modal-content {
  max-height: calc(80vh - 113px);
  background: #fff;
  overflow: auto;
  max-width: 343px;
  padding: 28px 20px;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .modal-content {
    margin: 0 16px;
    max-height: calc(80vh - 60px);
    max-width: initial;
  }
}
.modal-layer-inner {
  position: relative;
}
.modal-close-icon {
  position: absolute;
  top: -22px;
  right: -22px;
  cursor: pointer;
  pointer-events: none;
}
@media screen and (max-width: 767px) {
  .modal-close-icon {
    right: 18px;
  }
  .iframe-modal-layer-inner {
    .modal-close-icon {
      right: 0;
    }
  }
}
.modal-content-head {
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
  font-weight: bold;
  margin: 0 0 20px 0;
}
.modal-content-text {
  font-size: 14px;
  line-height: 1.5;
  margin: 0 0 12px 0;
}
.modal-content-text span {
  color: #000;
  font-weight: normal;
  white-space: break-spaces;
}
.formbox input[type="text"].referral-code {
  width: 100%;
}
.referral-button.button {
  width: 100px;
  height: 28px;
  margin: 5px 0 0 0;
}
.referral-button.button .button__label {
  font-size: 14px;
}
.question-modal {
  margin: 0 0 0 4px;
  position: relative;
  top: 1px;
  cursor: pointer;
}
.referral-plan-content {
  margin: 12px 0 0 0;
}
.referral-plan-content-head {
  font-size: 18px;
  line-height: 18px;
  font-weight: 700;
  margin: 0 0 12px 15px;
}
.referral-plan-content-inner {
  border: 2px solid #000;
  padding: 18px 15px 32px 15px;
}
.referral-plan-content-name {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  margin: 0 0 12px 0;
}
.referral-plan-content-description {
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
  white-space: break-spaces;
}
.fetch-loading {
  background: url(../images/common/fetch-loading.svg) no-repeat;
  width: 32px;
  height: 0;
  overflow: hidden;
  display: block;
}
.fetch-loading.show {
  height: 32px;
  margin: 10px 0 0 0;
}
.bank_note_text {
  text-align: center;
  margin: 30px 0 -30px 0;
  font-size: 16px;
  line-height: 30px;
}
@media screen and (max-width: 767px) {
  .bank_note_text {
    font-size: 14px;
    line-height: 26px;
    margin: 30px 0 0 0;
  }
}
@media screen and (max-width: 767px) {
  .form-payment-type-radio label {
    display: flex;
    line-height: 1.4;
  }
}
.formbox input[type=radio]:disabled + label {
  opacity: 0.5;
  cursor: default;
}
.optionLabel {
  input {
    margin: 0 8px 0 0;
  }
}
.formbox table.form-auto-option {
  width: 100%;
  margin: 10px 0 0 0;
}
.formbox table.form-auto-option tr:not(:last-child) th,
.formbox table.form-auto-option tr:not(:last-child) td {
  border-bottom: 2px solid #fff;
}
.formbox table.form-auto-option th,
.formbox table.form-auto-option td {
  border: none;
}
.formbox table.form-auto-option td .formbox__note {
  font-size: 16px;
  line-height: 28px;
  margin: 0;
}
.form-auto-option thead th {
  background-color: #000;
  color: #cba457;
  font-size: 22px;
  text-align: center;
}
.formbox .form-auto-option tbody th {
  width: 40%;
  input {
    display: none;
  }
}
.formbox table.form-auto-option td.autoOptionTd {
  background: none;
  padding: 0;
  .aform-required {
    margin: 0 10px 0 0;
  }
  label {
    line-height: 22px;
    input {
      margin: 0 8px 0 0;
    }
  }
  div {
    padding: 12px 0 0 0;
    display: flex;
    align-items: center;
  }
}
.formbox input[type=text].flatpickr-input {
  width: 30%;
}
@media only screen and (max-width: 999px) {
  .formbox input[type=text].flatpickr-input {
    width: 100%;
  }
}
.complete-popup-layer {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 999px) {
  .complete-popup-layer {
    top: 64px;
    height: calc(100% - 64px);
  }
}
.complete-content {
  width: 520px;
  max-width: 520px;
  max-height: 90vh;
  position: relative;
}
@media only screen and (max-width: 999px) {
  .complete-content {
    width: auto;
    margin: 0 32px;
  }
}
.complete-content-inner {
  overflow: auto;
  max-height: 70vh;
  background: #fff;
  padding: 48px 48px 28px 48px;
  position: relative;
}
@media only screen and (max-width: 999px) {
  .complete-content-inner {
    padding: 44px 20px 20px 20px;
  }
}
.complete-content-bg-pc {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}
@media only screen and (max-width: 999px) {
  .complete-content-bg-pc {
    display: none;
  }
}
.complete-content-bg-sp {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  display: none;
}
@media only screen and (max-width: 999px) {
  .complete-content-bg-sp {
    display: block;
  }
}
.complete-modal-close {
  position: absolute;
  top: -25px;
  right: -15px;
  background: none;
  cursor: pointer;
  padding: 0;
}
.complete-content-head-1 {
  font-weight: 700;
  font-size: 22px;
  line-height: 1.4;
  text-align: center;
  color: #AA863E;
  margin: 0 0 4px 0;
  position: relative;
}
.complete-content-head-1 ._sp {
  display: none;
}
@media only screen and (max-width: 999px) {
  .complete-content-head-1 ._sp {
    display: block;
  }
}
.complete-content-head-2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
  margin: 0 0 20px 0;
  position: relative;
}
.complete-content-blocks {
  max-width: 280px;
  margin: auto;
  padding: 0 0 40px 0 !important;
}
.complete-content-block img {
  max-width: 100%;
  height: auto;
}
.complete-content-block p:nth-of-type(1) {
  font-weight: 700;
  font-size: 18px;
  line-height: 1.4;
  text-align: center;
  margin: 12px 0 0 0;
}
.complete-content-block p:nth-of-type(2) {
  font-weight: 700;
  font-size: 14px;
  line-height: 1.4;
  text-align: center;
  margin: 0 0 12px 0;
}
.complete-content-block a:nth-of-type(1) {
  width: 180px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 15px;
  line-height: 1.4;
  color: #fff;
  background: #B7A266;
  margin: auto;
  text-decoration: none;
}
.complete-content-bottom {
  max-width: 280px;
  margin: 21px auto 0 auto;
}
.complete-content-bottom img {
  max-width: 100%;
  height: auto;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #CBA457;
}
@media only screen and (max-width: 999px) {
  body:has(.complete-popup-layer) .wrapper::before {
    opacity: .5;
    z-index: 100;
  }
}
.form-campaign-radio-input tr {
  display: flex;
  flex-flow: column;
  margin: 0 0 25px 0;
}
.form-campaign-radio-input tr th {
  width: 100%;
  display: block;
}
.form-campaign-radio-input tr td:has(span:empty) {
  display: none;
}
.form-campaign-radio-input tr td {
  width: 100%;
  display: block;
  border-top: none;
  padding: 10px 20px 0 20px;
  position: relative;
}
.form-campaign-radio-input tr td.open {
  padding: 10px 20px;
}
.form-campaign-radio-input tr td i {
  position: absolute;
  bottom: -15px;
  left: 0;
  right: 0;
  margin: auto;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background: url(../images/common/icon-plus.svg) no-repeat;
}
.form-campaign-radio-input tr td span {
  display: block;
  max-height: 78px;
  overflow: hidden;
}
.form-campaign-radio-input tr td span > em {
  font-style: normal;
}
.form-campaign-radio-input tr td span > em:nth-of-type(n+3) {
  color: #0000004D;
}
.form-campaign-radio-input tr td.open span > em:nth-of-type(n+3) {
  color: #000;
}
.form-campaign-radio-input tr td.open span {
  max-height: initial;
}
.form-campaign-radio-input tr td.open i {
  background: url(../images/common/icon-minus.svg) no-repeat;
}
.loading-plan {
  height: 100px;
  position: relative;
}