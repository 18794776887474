.global-navigation-container {
  background-color: #f0eff0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
}
.logo-text {
  position: relative;
  top: 5px;
}
.navigation-logo {
  display: none;

  @include mq {
    @include mq('large', max, true) {
      display: inline-block;

      a {
        display: block;
        padding-top: $spacing-small;
        padding-left: $spacing-middle;
      }
    }
  }
  @include mq('large', max) {
    display: inline-block;

    a {
      display: block;
      padding-top: $spacing-small;
      padding-left: $spacing-middle;

      img {
        width: 220px;
      }
    }
  }
  @include mq('small', max) {
    a {
      padding-left: $spacing-small;

      img {
        width: 200px;
      }
    }
  }
}

//ハンバーガーメニュー
.header-text-sp {
  color: $fg-secondary-color;
  font-size: 13px;
  left: 270px;
  position: absolute;
  top: 35px;

  @media screen and (max-width: 540px) {
    font-size: 12px;
    left: 40px;
    top: 2px;
  }
}

.header-text-pc {
  color: $fg-secondary-color;
  font-size: 14px;
  left: 230px;
  position: absolute;
  top: 16px;
  width: 100%;
}

.navigation-toggle {
  display: none;

  @include mq('large', max) {
    cursor: pointer;
    display: block;
    height: 32px;
    position: fixed;
    right: 40px;
    top: $spacing-small;
    z-index: 10000;

    > div {
      background-color: white;
      height: 51px;
      margin: 0 auto;
      position: relative;
      width: 39px;
    }

    span {
      background: #333333;
      display: block;
      height: 1px;
      left: 4px;
      position: absolute;
      transition: transform 0.6s ease-in-out, top 0.5s ease;
      width: 80%;

      &:nth-child(1) {
        top: 9px;
      }

      &:nth-child(2) {
        top: 18px;
      }

      &:nth-child(3) {
        top: 27px;
      }

      &:nth-child(4) {
        background: none;
        font-size: 11px;
        left: 4.5px;
        top: 30px;
      }
      
      &:nth-child(5) {
        background: none;
        display: none;
      }
    }

  }
  @include mq('small', max) {
    right: 10px;
  }
}

.open {
  .navigation-toggle {
  
    > div {
        background-color: initial;
      }
    
    span {
      background: #ffffff;
      color: white;

      &:nth-child(1) {
        top: 15px;
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        left: 50%;
        top: 15px;
        width: 0;
      }

      &:nth-child(3) {
        top: 15px;
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        background: none;
        display: none;
      }

      &:nth-child(5) {
        background: none;
        display: initial;
        font-size: 11px;
        left: 4px;
        top: 30px;
      }
    }
  }
}

//PC表示のグローバルナビ
.global-navigation {
  align-items: center;
  display: flex;
  height: 90px;
  justify-content: space-between;
  margin-top: $spacing-small;
  padding: 0 40px;
  text-transform: uppercase;

  h1 {
    img {
      height: auto;
      width: 225px;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &-list {
    li {
      display: inline-block;
      z-index: 6;

      & + li {
        margin-left: 33px;
      }
    }

    a {
      font-size: $fz-default-text;
      font-weight: bold;
      position: relative;
      text-decoration: none;

      &::after {
        background: #333333;
        bottom: -8px;
        content: '';
        height: 2px;
        left: 0;
        position: absolute;
        transform: scale(0, 1);
        transform-origin: left top;
        transition: transform 0.3s;
        width: 100%;
      }

      &:hover::after {
        transform: scale(1, 1);

        @include mq('large', max) {
          display: none;
        }
      }
    }
  }

  //ハンバーガーメニュー内
  @include mq('large', max) {
    height: initial;
    align-items: center;
    background: #000000;
    bottom: 0;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    justify-content: center;
    left: 0;
    margin-top: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    text-align: center;
    top: 0;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    visibility: hidden;
    z-index: 990;

    a {
      color: #ffffff;
      display: block;
      padding: 0;
      text-decoration: none;
      transition: color 0.6s ease;

      &:hover {
        color: #666666;

        @include mq('small', max) {
          color: #ffffff;
        }
      }
    }

    ul {
      list-style: none;

      li {
        opacity: 0;
        transform: translateX(200px);
        transition: transform 0.6s ease, opacity 0.2s ease;

        @for $i from 2 through 13 {
          &:nth-child(#{$i}) {
            transition-delay: 0.1s + 0.02 * $i;
          }
        }
      }
    }
  }
}

@include mq('large', max) {
  .open {
    overflow: hidden;

    //ハンバーガーメニュー開いたときの全画面
    .global-navigation {
      opacity: 1;
      visibility: visible;

      li {
        margin-left: 0;
        opacity: 1;
        transform: translateX(0);
        transition: transform 1s ease, opacity 0.9s ease;
        width: 100%;
      }
    }
  }
}

//グローバルメニュー内ドロップダウン
.global-dropdown-menu {
  background-color: #c59a59;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  display: none;
  padding: 8px 16px;
  position: absolute;
  top: 32px;
  width: 200px;
  z-index: 3;

  li {
    display: block;
    text-align: left;

    & + li {
      margin-left: 0;
      margin-top: 8px;
    }

    a {
      color: #ffffff;

      &::after {
        background-color: #ffffff;
      }
    }
  }

  &-parent {
    position: relative;
    z-index: 4;
  }

  @include mq('large', max) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.admission {
  background-color: #876430;;
  padding: 0 10px;
}

.admission_text {
  color: white!important;
}

@media screen and (max-width: 1200px) {
  .header-text-pc {
    font-size: 13px;
    left: 0px;
    top: -19px;
  }
  .logo-text {
    top: 12px;
  }
}

//IE11対応
 .is-msie {
  .global-navigation h1 img {
    height: 50px;
  }
  .navigation-logo a img {
    height: 50px;
  }
  .header-text-pc {
    left: 230px;
    top: 16px;
  }
  .admission {
    height: 40px;
    line-height: 2.7;
  }
  .global-dropdown-menu {
    height: 210px;

    li {
      line-height: 2;
    }
  }
  @media screen and ( max-width:1180px) {
    .header-text-pc {
      left: 0px;
      top: -20px;
    }
  }
  @media screen and ( min-width:1025px) {
    .header-text-sp {
      display: none;
    }
  }
} 
