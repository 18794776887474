.page {
  margin-top: $spacing-middle;
  padding: 0 40px;

  > h2 {
    font-family: $Serif;
    font-size: $fz-max;
    font-weight: 200;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }

  &.fail {
    section {
      margin: 0;
    }
  }

  @include mq('small', max) {
    margin-top: $spacing-small;
    padding: 0 $spacing-small;
  }
}

.page-contents {
  background-color: $bg-secondary-color;
  margin: $spacing-middle 40px 0 40px;
  padding: $spacing-middle 40px;

  &.center {
    text-align: center;
  }

  .button {
    margin-top: 32px;

    @include mq('small', max) {
      display: block;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      width: 100%;
    }
  }

  section {
    margin-top: 32px;

    h3 {
      & + * {
        margin-top: 32px;
      }

      @include mq('large', max) {
        & + * {
          margin-top: $spacing-small;
        }
      }
    }

    p + p {
      margin-top: 24px;
      @include mq('large', max) {
        margin-top: $spacing-small;
      }
    }

    @include mq('large', max) {
      margin-top: 0;
    }

    @include mq('small', max) {
      margin-top: 0;
    }
  }

  &.shop-detail {
    margin: 60px 0;

    > div + section,
    > section + section {
      margin-top: 120px;
    }

    @include mq('large', max) {
      margin: $spacing-middle 0;
    }
    @include mq('small', max) {
      padding: $spacing-small $spacing-small;

      .button {
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
      }
    }
  }

  &.franchise {
    section {
      margin-top: $spacing-small;
    }
  }

  @include mq('large', max) {
    margin: $spacing-small 0 0 0;
    padding: $spacing-small $spacing-small;
  }
  @include mq('small', max) {
    margin: $spacing-small 0 0 0;
    padding: $spacing-small;
  }
}

.page-content {
  > h3 {
    font-size: $fz-36;
    font-weight: bold;
  }

  > p {
    font-size: $fz-default-text;
    margin-top: 24px;

    small {
      font-size: $fz-small;
    }

    @include mq('large', max) {
      margin-top: 0;
    }
  }

  & + section {
    margin-top: $spacing-large;
  }

  &.privacy-policy {
    p,
    li {
      font-weight: normal;
    }

    h3 + p {
      margin-top: 16px;
    }
  }

  &.system {
    //width: $sub-content-size;
    margin: 0 auto;

    @include mq('large', max) {
      width: 100%;
    }
  }

  &.membership-constitution {
    ol {
      counter-reset: section;
    }

    ol > li::before {
      content: counters(section, '-') '. ';
      counter-increment: section;
    }

    li {
      list-style-type: none;
    }

    p,
    li {
      font-weight: normal;
    }

    h3 + * {
      margin-top: 16px;
    }
  }

  @include mq('small', max) {
    h3 {
      font-size: $fz-26;
    }
    p {
      font-size: $fz-sp-default-text;
    }
  }
}

.text-container {
  //width: $sub-content-size;
  font-size: $fz-default-text;
  margin: 16px auto 0 auto;
  text-align: left;

  div + div {
    margin-top: 36px;
  }

  &.fail,
  &.notfound {
    width: 50%;

    @include mq('small', max) {
      width: 100%;
    }
  }

  @include mq('large', max) {
    width: 100%;
  }
  @include mq('large', max) {
    width: 100%;
    margin-top: $spacing-small;

    div + div {
      margin-top: $spacing-small;
    }
  }
}
