#shop-detail {
  .shop-thumbnail-container {
    margin-top: $spacing-small;
    padding: 0 $spacing-middle;
    @include mq('small', max) {
      padding: 0 $spacing-small;
    }

    .shop-thumbnail-wrap {
      height: 300px;
      position: relative;
      width: 100%;

      &::before,
      img {
        bottom: 0;
        height: 300px;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
      }

      &::before {
        background-color: rgba(0, 0, 0, 0.3);
        content: '';
        z-index: 1;
      }

      > img {
        object-fit: cover;
        object-position: center center;
      }

      > h1 {
        font-size: 4.5rem;
        font-weight: bold;
        left: 50%;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        z-index: 5;
        line-height: 1.5;
        background-color: rgba(255,255,255,0.8);
        text-align: center;
      }

      @include mq('small', max) {
        height: 200px;

        > img,
        &::before {
          height: 200px;
        }
      }
    }
  }

  .shop-detail-container {
    display: flex;
    justify-content: space-between;

    @include mq('large', max) {
      flex-direction: column-reverse;
    }
  }

  .shop-detail-excerpt {
    margin-top: 0;
    width: 60%;

    h2 {
      font-size: $fz-46;

      & + p {
        margin-top: 24px;
      }
    }

    p {
      margin-top: 0px;
    }

    .shop_open_date  {
      margin: 24px 0px;
    }

    // [NOTE]-----------------
    // 外部からテキストをコピペした際、
    // preタグに囲まれてフォントと字間が崩れてしまうのを以下で対応
    // -----------------------
    pre {
      font-family: 'kozuka-pr6n-r';
      white-space: normal;
    }
    .shop-detail-table {
      margin-top: 24px;
      table-layout: fixed;

      th {
        text-align: left;
        width: 70px;
      }

      th + td {
        padding-left: 16px;
      }

      tr {
        display: block;
        padding: 4px 0;
      }
    }

    @include mq('large', max) {
      width: 100%;
    }

    @include mq('small', max) {
      h2 {
        font-size: $fz-24;
      }
    }
  }

  .shop-detail-image-container {
    margin-left: 32px;
    max-width: 550px;
    width: 40%;

    img {
      display: block;
      height: 345px;
      margin: 0 auto;
      object-fit: cover;
      width: 100%;
    }

    @include mq('large', max) {
      margin-left: 0;
      max-width: initial;
      width: 100%;
      text-align: center;
    }
    @include mq('small', max) {
      > img {
        height: 200px;
      }
    }
  }

  .shop-access-container {
    margin-top: 120px;

    > h2 {
      font-size: $fz-46;

      & + p {
        margin-top: 24px;

        & + p {
          margin-top: 16px;
        }
      }
    }

    p {
      margin-top: 0px;
    }

    .fr-green {
      color: rgb(76,175,80)
    }

    .shop_zipcode_text {
      margin: 24px 0px;
    }

    // [NOTE]-----------------
    // 外部からテキストをコピペした際、
    // preタグに囲まれてフォントと字間が崩れてしまうのを以下で対応
    // -----------------------
    pre {
      font-family: 'kozuka-pr6n-r';
      white-space: normal;
    }

    @include mq('large', max) {
      margin-top: 60px;
    }
    @include mq('small', max) {
      margin-top: $spacing-middle;
      > h2 {
        font-size: $fz-24;
      }
    }
  }

  .shop-map {
    height: 0;
    margin-top: 40px;
    overflow: hidden;
    padding-bottom: 31.25%;
    position: relative;

    iframe {
      height: 398px;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    @include mq {
      @include mq('small', max, true) {
        padding-bottom: 200px;
      }
    }
    @include mq('small', max) {
      padding-bottom: 200px;
    }
  }

  .top-informations.container {
    margin: 0 auto;
    padding: 40px 0;
    .grid-row {
      margin: 0 auto;
    }
    .time {
      text-align: center;
      font-size: 0.75rem;
    }
  }

  .shop-news {
    margin: 100px auto 0 auto;

    > h2 {
      font-size: $fz-46;
    }
    .small-card-thumbnail {
      width: 100%;
    }

    @include mq('small', max) {
      margin-top: $spacing-middle;
      > h2 {
        font-size: $fz-24;
      }
    }
  }

  .shop-sns {
    margin-top: 140px;
    padding: 0 40px;

    &-heading {
      display: flex;

      .heading-container {
        > h2 {
          font-size: $fz-32;
        }
      }

      ul {
        display: flex;
        margin-left: 32px;

        li + li {
          margin-left: 16px;
        }
      }
    }

    &-contents {
      max-width: $instagram-contents;
      margin: 40px auto 0 auto;

      ul {
        @include mq('small', max) {
          justify-content: center;
        }

        li {
          margin: 8px auto;
          width: 200px;

          img {
            height: 200px;
            width: 200px;
            object-fit: cover;
            object-position: center center;
          }
        }
      }

      @include mq('small', max) {
        ul {
          display: block;

          li {
            margin: 8px auto 16px auto;
          }
        }
      }
    }

    @include mq('small', max) {
      margin-top: $spacing-middle;
      padding: 0 $spacing-small;
    }
  }

  .shop-feature {
    margin-top: 140px;
    padding: 0 40px;

    > p {
      font-size: $fz-default-text;
      font-weight: bold;
      text-align: center;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 40px;
      padding: 0 80px;

      li {
        padding: 30px;
        width: calc(100% / 3);

        img {
          display: block;
          height: 100px;
          margin: 0 auto;
          text-align: center;
          width: 100px;
        }

        p {
          font-size: $fz-default-text;
          text-align: center;
        }
      }
    }

    &-icons {
      max-width: $sub-content-size;
      margin: 0 auto;
    }
  }

  .map {
    position: relative;
    overflow: hidden;
    height: 400px;
  }
  .image_subtext {
    text-align: right;
    display: block;
  }

  .button-wrap {
    display: flex;
    margin: 60px auto;
  }
  .button {
    display: inline-block;
    margin-right: 20px;
    padding: 16px 50px;
  }
  .facebook {
    width: 47px;
  }

  @include mq('large', max) {
    .shop-thumbnail-container {
      .shop-thumbnail-wrap {
        h1 {
          font-size: $fz-input;
        }
      }
    }
  }

  @include mq('medium', max) {
    .button-wrap {
      flex-direction: column;
      text-align: -webkit-center;
    }
    .button {
      margin: 20px auto;
      width: 80%;
    }
    .shop-thumbnail-container {
      .shop-thumbnail-wrap {
        h1 {
          font-size: $fz-46;
        }
      }
    }
    .shop-sns {
      &-heading {
        display: block;

        ul {
          margin-top: 10px;
        }
      }
    }
    .shop-feature {
      ul {
        padding: 0;

        li {
          width: calc(100% / 2);
          padding: 15px;
        }
      }
    }
  }

  @include mq('small', max) {
    .button {
      padding: 16px 30px;
    }
    .shop-thumbnail-container {
      .shop-thumbnail-wrap {
         h1 {
          font-size: $fz-26;
        }
      }
    }
    .shop-sns {
      &-heading {
        > h2 {
          font-size: $fz-24;
        }
        ul {
          margin-left: 0;
          margin-top: 8px;

          img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
    .shop-feature {
      margin-top: $spacing-middle;
      padding: 0 10px;

      ul {
        margin-top: $spacing-small;
        padding: 0;

        li {
          width: calc(100% / 2);
          padding: 0 5px;

          img {
            width: 80px;
            height: 80px;
          }

          p {
            font-size: $fz-sub-text;
            margin: 0 auto;
          }

          &:nth-child(n + 3) {
            margin-top: 16px;
          }
        }
      }

      &-icons {
        max-width: 100%;
      }
    }
  }
}
//IE11対応
// @media all and (-ms-high-contrast: none){
.is-msie {
  #shop-detail {
    .shop-feature {
      ul {
        justify-content: space-around;
      }
    }

    @include mq('large', max) {
      .shop-detail-excerpt {
        h2 {
          margin-top: 20px;
        }
      }
    }
    @media screen and ( max-width:1550px) {
      .shop-news {
        >h2 {
          margin-top: 40px;
        }
      }
    }
    @media screen and ( max-width:1025px) {
      .shop-news {
        >h2 {
          margin-bottom: 20px;
        }
      }
    }
  }
}
