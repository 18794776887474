#equipment{
  .equipment-container {
    margin-top: $spacing-small;
  }

  .equipment-content {
    div {
      text-align: center;
      
      img {
        width: 80%;
        height: auto;
        margin: auto;
      }

      p {
        text-align: center;
      }
    }
  }
  .grid-row {
    justify-content: space-around;
    width: 80%;
  }

  //IE11対応
  @media all and (-ms-high-contrast: none){
    .grid-row {
      width: 100%;
      margin: $spacing-middle auto 0 auto;
      @include grid-row(auto-fit, 3, minmax(372px, 1fr), 36px, 36px);
    }
  }
}