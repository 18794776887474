.informations-detail {
  display: flex;

  &-container {
    margin-top: $spacing-small;
    padding: 0 $spacing-middle;

    @include mq('small', max) {
      padding: 0 $spacing-small;
    }
  }

  &-contents {
    width: 50%;

    > h2 {
      font-family: $Serif;
      font-size: $fz-max;
      font-weight: normal;
      letter-spacing: 5.29px;
      line-height: 1em;
      text-transform: uppercase;
    }
  }

  &-heading {
    margin-top: $spacing-middle;

    > h3 {
      font-size: $fz-32;
      font-weight: normal;
    }

    > p {
      font-size: $fz-sub-text;
      margin-top: $spacing-small;
    }
  }

  &-note {
    margin-top: 32px;

    * + * {
      margin-top: 32px;
    }

    li + li {
      margin-top: 16px;
    }

    img {
      object-fit: contain;
      width: 100%;
    }
  }

  &-thumbnail {
    margin-left: 32px;
    width: calc(50% - 32px);
  }

  &-button-list {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: $spacing-middle;

    a + a {
      margin-top: 40px;
    }
  }

  @include mq('large', max) {
    flex-direction: column;
    &-contents {
      width: 100%;
    }
    &-thumbnail {
      width: 100%;
      margin-left: 0;
      margin-top: $spacing-small;

      img {
        display: block;
        margin: 0 auto;
      }
    }
  }
  @include mq('small', max) {
    &-heading {
      > h3 {
        font-size: $fz-26;
      }
    }
    &-thumbnail {
      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
  }
}

.image {
  width: 100%;
}

//IE11対応
.is-msie {
  .informations-detail-note {
    p {
      img {
        margin-top: 100px;
      }
    }
  }
}
