#footer {
 .footer {
    margin-bottom: 80px;
    margin-top: 140px;
  }

  .logo_area {
    align-items: flex-end;
    display: flex;
    justify-content: center;
  }

  .w_logo {
    margin-right: 50px;
    width: 200px;
  }

  .footer-navigation {
    &-container {
      margin: 0 auto;
      width: calc(100% - 20%);
      padding: 0 $spacing-middle;

      li {
        list-style-type: none;
      }

      a {
        text-decoration: none;
      }
    }

    &-primary-list {
      display: flex;
      justify-content: space-between;

      > li {
        display: inline-block;

        > a {
          font-weight: bold;
        }

        > ul {
          margin-top: 16px;

          li + li {
            margin-top: 16px;
          }

          a {
            padding-left: 1rem;
          }
        }
      }
    }

    &-sns-list {
      margin-top: $spacing-middle;
      text-align: center;

      li {
        display: inline-block;

        & + li {
          margin-left: $spacing-middle;
        }
      }

      img {
        height: auto;
        width: 47px;
      }
    }

    &-secondary-content {
      margin-top: $spacing-xsmall;

      .footer-navigation-logo {
        display: block;
        margin: 0 auto;
        text-align: center;
        width: 200px;
      }
    }
  }

  .footer-navigation-secondary-list {
    margin-top: $spacing-small;
    text-align: center;

    li {
      display: inline-block;

      & + li {
        margin-left: $spacing-small;
      }
    }
  }

  .pc-hide {
    display: block;
    text-align: center;
  }
  .button-wrap {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .button {
      padding: 16px 50px;
      margin-left: 10px;
    }
  }

  .footer-navigation-logo_sp {
    max-width: 300px;
    width: 50%;
  }
  
  @include mq('large', max) {
    .footer {
      margin-bottom: $spacing-middle;
      margin-top: 70px;
    }
    .footer-navigation {
      width: 100%;
    }
    .footer-navigation-primary-list {
      display: none;
    }

    .footer-navigation-secondary-list {
      li {
        text-align: center;

        li {
          &:first-child {
          }

          &:not(:first-child) {
            margin-top: 32px;

            & + li {
              margin-left: 16px;
            }
          }
        }

      }
    }

  }

  @include mq {
    @include mq('large', max, true ) {
      .footer {
        padding: 0 $spacing-small !important;
        margin-bottom: $spacing-middle;
        margin-top: 70px;
      }
      .footer-navigation-container {
        width: 100%;
      }
    }
  }

  @include mq('medium', max) {
    .button-wrap {
      flex-direction: column;
      margin: auto;
      text-align: -webkit-center;
      width: 80%;

      .button {
        margin-top: 10px;
        padding: 10px 5px;
      }
    }
  }

  @include mq('small', max) {
    .footer-navigation-secondary-list {
      li {
        margin-top: 8px !important;
        display: block;

        & + li {
          margin-left: 0 !important;
        }
      }
    }
  }
}

//IE11対応
.is-msie {
  #footer {
    .footer-navigation-container {
      .sp-hide {
        li {
          margin-top: 10px;
        }
      }
    }
    .logo_area {
      align-items: center;
    }
    .footer-navigation-sns-list {
      margin-top: 0;
    }
    .footer-navigation-sns-list img {
      height: 47px;
    }
    @include mq('medium', max) {
      .footer-navigation-sns-list {
        margin-top: 40px;
      }
    }

  }
}
