.text-link-button {
  appearance: none;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #707070;
  font-size: $fz-default-text;
  font-weight: 200;
  margin-top: 40px;
  outline: 0;
  padding: 0 0 10px 0;
  position: relative;
  text-decoration: none;
  transition: all 300ms ease-in-out;

  @include mq('large', max) {
    font-size: $fz-sp-default-text;
  }

  &:focus {
    outline: 0;
  }

  &:hover {
    // letter-spacing: 1px;
    // padding: 0 8px 10px 8px;
    // transition: all 300ms ease-in-out;

    @include mq('large', max) {
      letter-spacing: 0;
      padding: 0 0 10px 0;
    }
  }

  &.bold {
    font-weight: bold;
  }
}
