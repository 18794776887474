.text-link {
  border-bottom: 1px solid #707070;
  display: inline-block;
  font-size: $fz-default-text;
  font-weight: 200;
  padding: 0 0 10px 0;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 300ms ease-in-out;

  &:hover {
    letter-spacing: 1.75px;
    padding: 0 8px 10px 8px;
    transition: all 300ms ease-in-out;
  }

  @include mq('large', max) {
    &:hover {
      letter-spacing: 0;
      padding: 0 0 10px 0;
    }
  }
}
