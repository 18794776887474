// reset
body,
html {
  background-color: $bg-theme-color;
  font-family: $Sans-serif;
  font-size: $fz-default-text;

  @include mq('large', max) {
    font-size: $fz-sp-default-text;
  }

  &.modal-active {
    overflow: hidden;
  }
}

li {
  list-style-type: none;
}

select::-ms-expand {
  display: none;
}

button {
  -webkit-appearance: none;
  border-radius: 0;
  border-style:none;
}
