.large-card {
  background-color: #ffffff;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.08);
  height: 340px;
  transition: all 250ms cubic-bezier(0.02, 0.01, 0.47, 1);
  margin: 0 auto;
  width: 100%;
  @include mq('large', max) {
    width: 100%;
    height: auto;
  }

  &:hover {
    .large-card-thumbnail {
      &::before {
        background-color: rgba(0, 0, 0, 0.4);
        bottom: 0;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: background-color 300ms ease-in-out;
        width: 100%;
      }
    }
  }

  a {
    display: flex;
    height: 100%;
    text-decoration: none;

    @include mq('large', max) {
      display: block;
    }
  }

  &-thumbnail {
    position: relative;
    width: 316px;

    @include mq('large', max) {
      width: 100%;
      height: $card-thumbnail-height;
    }

    &::before {
      background-color: rgba(0, 0, 0, 0);
      bottom: 0;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: background-color 300ms ease-in-out;
      width: 100%;
    }

    img {
      height: 100%;
      object-fit: cover;
      width: inherit;
    }
  }

  &-detail {
    padding: 24px;

    @include mq('large', max) {
      padding: 24px 30px;
      display: flex;
      flex-direction: column-reverse;
    }

    p {
      @include mq('large', max) {
        margin-top: 48px;
        text-align: center;
      }

      > time {
        font-size: $fz-small;
        font-weight: bold;

        @include mq('large', max) {
          font-weight: normal;
        }
      }
    }

    h3 {
      font-size: $fz-24;
      font-weight: bold;
      line-height: calc(24px * 1.75);

      @include mq('large', max) {
        font-size: $fz-22;
        line-height: calc(22px * 1.75);
      }

      & + p {
        font-size: $fz-small;
        line-height: calc(12px * 1.75);
        margin-top: 40px;
        padding-top: 40px;
        position: relative;

        @include mq('large', max) {
          display: none;
        }

        &::before {
          background-color: #333333;
          content: '';
          height: 2px;
          left: 0;
          position: absolute;
          top: 0;
          width: 64px;

          @include mq('large', max) {
            display: none;
          }
        }
      }
    }
  }
}

//IE11対応
_:-ms-lang(x)::-ms-backdrop,
  .large-card-thumbnail img {
    width: 100%;
  }
  .large-card-detail {
    width: 100%;
  }
  #top {
    .large-card {
      width: 100%;
    }
  }